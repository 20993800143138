<template>
  <section>
    <validation-observer ref="rules">
      <b-card >
        <b-card-header>
          <b-card-title>{{ $t('Order') }} {{ $t('Details') }}</b-card-title>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col lg="4">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Customer"
                  rules="required"
                >
                  <label class="ll-boldText">{{ $t("Customer") }}</label>
                  <v-select
                    v-model="customer"
                    :options="customerList"
                    label="clientCode"
                    @input="selCustomer"
                    placeholder=""
                  ></v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Order Number"
                  rules="required"
                >
                  <label class="ll-boldText">{{ $t("Order Number") }}</label>
                  <b-form-input v-model="createOrderInfo.order_number"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="4">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Create Date"
                  rules="required"
                >
                  <label class="ll-boldText">{{ $t("Create Date") }}</label>
                  <flat-pickr
                    v-model="createOrderInfo.order_creation_time"
                    class="form-control"
                    :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss'}"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="WMS Integration Name"
                  rules="required"
                >
                  <label class="ll-boldText">{{ $t("WMS Integration Name") }}</label>
                  <v-select
                    :options="warehouseList"
                    @input="selWarehouse"
                    label="warehouseName"
                    placeholder=""
                  ></v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="4">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Warehouse"
                  rules="required"
                >
                  <label class="ll-boldText">{{ $t("Warehouse") }}</label>
                  <b-form-input :disabled="true" v-model="warehouse"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Order Type"
                  rules="required"
                >
                  <label class="ll-boldText">{{ $t("Order") }}{{ $t("Type") }}</label>
                  <v-select
                    v-model="orderType"
                    :options="orderTypeList"
                    label="codeDescription"
                    @input="selOrderType"
                  >
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="4">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Shipment Term"
                  rules="required"
                >
                  <label class="ll-boldText">{{ $t("Shipment Term") }}</label>
                  <v-select
                    v-model="shipmentTerm"
                    :options="shipmentTermList"
                    label="codeDescription"
                    @input="selShipmentTerm"
                  >
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Logistics Provider"
                  rules="required"
                >
                  <label class="ll-boldText">{{ $t("Logistics Provider") }}</label>
                  <v-select
                    :options="providerList"
                    label="serviceCode"
                    @input="selProvider"
                    v-model="provider"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="4">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Payment method"
                  rules="required"
                >
                  <label class="ll-boldText">{{ $t("Payment method") }}</label>
                  <v-select
                    :options="payMethodList"
                    @input="selPayMethod"
                    label="key"
                    v-model="payMethod"
                  >
                    <template #option="{ key, des }">
                      <span class="ml-50 align-middle"> {{ key }}({{ des}})</span>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <b-card >
        <b-card-header>
          <b-card-title>{{ $t('Delivery Information') }}</b-card-title>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col lg="4">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Sender Name"
                  rules="required"
                >
                  <label class="ll-boldText">{{ $t("Sender Name") }}</label>
                  <b-form-input v-model="createOrderInfo.sender.name"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Sender Phone"
                  rules="required"
                >
                  <label class="ll-boldText">{{ $t("Sender Phone") }}</label>
                  <b-form-input v-model="createOrderInfo.sender.phone"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="12">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Sender Address"
                  rules="required"
                >
                  <label class="ll-boldText">{{ $t("Sender Address") }}</label>
                  <b-form-input v-model="createOrderInfo.sender.address"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="4">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Sender City"
                  rules="required"
                >
                  <label class="ll-boldText">{{ $t("Sender City") }}</label>
                  <b-form-input v-model="createOrderInfo.sender.city"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Sender State"
                  rules="required"
                >
                  <label class="ll-boldText">{{ $t("Sender State") }}</label>
                  <b-form-input v-model="createOrderInfo.sender.district"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Sender Country"
                  rules="required"
                >
                  <label class="ll-boldText">{{ $t("Sender Country") }}</label>
                  <v-select
                    v-model="country1"
                    :options="countryList"
                    label="codeDescription"
                    @input="selCountry1"
                  >
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <p></p>
          <b-row>
            <b-col lg="4">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Consignee Name"
                  rules="required"
                >
                  <label class="ll-boldText">{{ $t("Consignee Name") }}</label>
                  <b-form-input v-model="createOrderInfo.receiver.name"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Consignee Phone"
                  rules="required"
                >
                  <label class="ll-boldText">{{ $t("Consignee Phone") }}</label>
                  <b-form-input v-model="createOrderInfo.receiver.phone"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="12">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Consignee Address"
                  rules="required"
                >
                  <label class="ll-boldText">{{ $t("Consignee Address") }}</label>
                  <b-form-input v-model="createOrderInfo.receiver.address"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="4">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Consignee City"
                  rules="required"
                >
                  <label class="ll-boldText">{{ $t("Consignee City") }}</label>
                  <b-form-input v-model="createOrderInfo.receiver.city"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Consignee State"
                  rules="required"
                >
                  <label class="ll-boldText">{{ $t("Consignee State") }}</label>
                  <b-form-input v-model="createOrderInfo.receiver.district"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Consignee Country"
                  rules="required"
                >
                  <label class="ll-boldText">{{ $t("Consignee Country") }}</label>
                  <v-select
                    v-model="country2"
                    :options="countryList"
                    label="codeDescription"
                    @input="selCountry2"
                  >
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>

    <b-card >
      <b-card-header class="ll-cardHead">
        <b-card-title>{{ $t('Items') }}</b-card-title>
        <b-button
          variant="outline-primary"
          class="ml-1"
          @click="openSKU"
        >
          {{ $t('Add') }} {{ $t('Item') }} +
        </b-button>
      </b-card-header>
      <b-card-body>
        <vxe-table
          border
          show-overflow
          keep-source
          ref="xTable"
          max-height="400"
          :data="item"
          :edit-config="{ trigger: 'manual', mode: 'row', showStatus: true }"
          :empty-text="$t('No matching records found')"
        >
          <vxe-table-column field="name"
            :title="$t('Item')"
            :edit-render="{name: 'input'}"
          >
            <!-- <template #default="{ row }">
              <b-form-input v-model="row.itemName"/>
            </template> -->
          </vxe-table-column>
          <vxe-table-column field="item_code"
            title="SKU"
            :edit-render="{name: 'input'}"
          >
            <!-- <template #default="{ row }">
              <b-form-input v-model="row.skuCode"/>
            </template> -->
          </vxe-table-column>
          <vxe-table-column field="qty" title="QTY"
            :edit-render="{name: 'input', attrs: {type: 'number'}}"
          >
            <!-- <template #default="{ row }">
              <b-form-input v-model="row.qty"/>
            </template> -->
          </vxe-table-column>
          <vxe-table-column field="unit_price"
            :title="$t('Price')"
            :edit-render="{name: 'input', attrs: {type: 'number'}}"
          >
            <!-- <template #default="{ row }">
              <b-form-input v-model="row.unitPrice"/>
            </template> -->
          </vxe-table-column>
          <vxe-table-column field="currency"
            :title="$t('Currency')"
            :edit-render="{name: 'input'}"
          >
            <!-- <template #default="{ row }">
              <b-form-input v-model="row.currency"/>
            </template> -->
          </vxe-table-column>
          <vxe-table-column field="origin_country"
            :title="$t('Origin')"
            :edit-render="{name: 'input'}"
          >
            <!-- <template #default="{ row }">
              <b-form-input v-model="row.origin"/>
            </template> -->
          </vxe-table-column>
          <vxe-table-column field=""
            title="UDF"
            :edit-render="{name: 'input'}"
          >
            <!-- <template #default="{ row }">
              <b-form-input v-model="row.userDefinedField"/>
            </template> -->
          </vxe-table-column>
          <vxe-table-column field=""
            :title="$t('Batch No')+'.'"
            :edit-render="{name: 'input'}"
          >
            <!-- <template #default="{ row }">
              <b-form-input v-model="row.batchNumber"/>
            </template> -->
          </vxe-table-column>
          <vxe-table-column field=""
            :title="$t('Expiry Day')"
            :edit-render="{name: 'input'}"
          >
            <!-- <template #default="{ row }">
              <b-form-input v-model="row.expiryDay"/>
            </template> -->
          </vxe-table-column>
          <vxe-table-column field=""
            :title="$t('Condition')"
            :edit-render="{name: 'input'}"
          >
            <!-- <template #default="{ row }">
              <b-form-input v-model="row.condition"/>
            </template> -->
          </vxe-table-column>
          <vxe-table-column :title="$t('Action')" width="120" show-overflow>
            <template #default="{ row }" >
              <template v-if="$refs.xTable.isActiveByRow(row)">
                <b-button
                  variant="primary"
                  class="mr-1"
                  @click="saveRowEvent(row)"
                >
                  <span>{{ $t('Update') }}</span>
                </b-button>
                <b-button
                  variant="secondary"
                  @click="cancelRowEvent(row)"
                >
                  <span>{{ $t('Cancel') }}</span>
                </b-button>
              </template>
              <template v-else>
                <vxe-button type="text" @click="editRowEvent(row)">
                  <feather-icon icon="EditIcon" />
                </vxe-button>
                <vxe-button type="text" icon="" @click="removeEvent(row)">
                  <feather-icon icon="Trash2Icon" />
                </vxe-button>
              </template>
              <!-- <vxe-button type="text" @click="editRowEvent(row)">
                <feather-icon icon="EditIcon" />
              </vxe-button>
              <vxe-button type="text" icon="" @click="removeEvent(row)">
                <feather-icon icon="Trash2Icon" />
              </vxe-button> -->
            </template>
          </vxe-table-column>
        </vxe-table>
      </b-card-body>
    </b-card>
    <div class="d-flex justify-content-end">
      <b-button
        variant="outline-secondary"
        class="btn mr-1"
        @click="$emit('createModel', isCreateModel)"
      >
        {{ $t('Cancel') }}
      </b-button>
      <b-button
        variant="primary"
        class="btn"
        @click.prevent="validationForm"
      >
        {{ $t('Create') }}
      </b-button>
    </div>
    </validation-observer>
    <!-- Adding line item -->
    <b-modal
      id="modal-addLineItem"
      cancel-variant="outline-secondary"
      ok-title=""
      centered
      title="Adding items"
      size="xl"
      v-model="isShow"
    >
      <b-input-group class="input-group-merge mb-1">
        <b-form-input
          placeholder="Search...(Item name or SKU)"
          type="search"
          @input="handleSearch"
        />
        <b-input-group-append is-text>
          <feather-icon
            icon="SearchIcon"
            class="cursor-pointer"
          />
        </b-input-group-append>
      </b-input-group>
      <vxe-table
        border
        show-overflow
        keep-source
        ref="xTable1"
        max-height="400"
        :data="addItem"
        :edit-config="{ trigger: 'click', mode: 'row', showStatus: true }"
        :empty-text="$t('No matching records found')"
        @checkbox-change="getSelectionEvent"
        @checkbox-all="getSelectionEvent"
      >
        <vxe-column type="checkbox" width="60"></vxe-column>
        <vxe-table-column field="itemDesc" :title="$t('Item')" >
        </vxe-table-column>
        <vxe-table-column field="itemCode" title="SKU" >
        </vxe-table-column>
        <vxe-table-column field="availableQty" :title="$t('Inventory')" >
        </vxe-table-column>
        <vxe-table-column field="unitPrice" :title="$t('Price')" >
        </vxe-table-column>
        <vxe-table-column field="currency" :title="$t('Currency')" >
        </vxe-table-column>
        <vxe-table-column field="coo" :title="$t('Origin')" >
        </vxe-table-column>
        <vxe-table-column field="userDefinedField" title="UDF" >
        </vxe-table-column>
        <vxe-table-column field="batchNumber" :title="$t('Batch No')+'.'" >
        </vxe-table-column>
        <vxe-table-column field="expiryDay" :title="$t('Expiry Day')" >
        </vxe-table-column>
        <vxe-table-column field="condition" :title="$t('Condition')" >
        </vxe-table-column>
        <vxe-table-column field="qty" title="QTY" >
          <template #default="{ row }">
            <b-form-input v-model="row.qty" :disabled="row.isDisable" type="number"/>
          </template>
        </vxe-table-column>
      </vxe-table>
      <p class="ll-textAlignRight mt-1 mr-1">
        <span class="font-weight-bolder">Total: {{addItem.length}}</span>
      </p>
      <template #modal-footer>
        <b-button
          variant="outline-secondary"
          class="btn"
          @click="isShow = false"
        >
          {{ $t('Cancel') }}
        </b-button>
        <b-button
          variant="primary"
          class="btn"
          @click="addItems"
          :style="{'cursor': isShowBtn ? 'no-drop' : 'pointer'}"
          :disabled="isShowBtn"
        >
          {{ $t('Add') }}
        </b-button>
      </template>
    </b-modal>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BFormGroup, BFormInput,
  BButton,  BInputGroup, BModal, VBModal,
  BCardHeader, BCardTitle, BCardBody,
  BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, emails } from '@validations'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroup,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BModal,
    BInputGroupAppend,
    BFormCheckbox,
    flatPickr,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      required,
      emails,
      item: [],
      isShow: false,
      addItem: [],
      selectRecords: null,
      createDate: '',
      customerList:[],
      customer: '',
      orderTypeList: [],
      orderType: '',
      shipmentTermList: [],
      shipmentTerm: '',
      payMethodList: [
        { key: 'PP', des: 'Prepaid' },
        { key: 'COD', des: 'Cash on delivery' },
      ],
      payMethod: '',
      countryList: [],
      country1: '',
      country2: '',
      warehouseList: [],
      createOrderInfo: {
        action_type: 'ADD',
        merchant_code: null,
        warehouse_code: null,
        order_number: null,
        order_creation_time: null,
        shipment_term: null,
        order_type: null,
        logistics_provider: {},
        payment_method: null,
        goods_value_currency: 'HKD',
        is_consolidation: 'N',
        items: [],
        sender: {
          name: null,
          phone: null,
          country_code: null,
          address: null,
          city: null,
          district: null,
        },
        receiver: {
          name: null,
          phone: null,
          country: null,
          country_code: null,
          city: null,
          district: null,
        }
      },
      isCreateModel: false,
      providerList: [],
      provider: null,
      warehouse: null,
      isShowBtn: true,
    }
  },
  mounted(){
    this.queryCustomer()
    this.queryOrderType()
    this.queryShipmentTerm()
    this.queryCountry()
    // this.queryWarehouse()
  },
  methods: {
    openSKU() {
      this.isShow = true
      this.isShowBtn = true
    },
    pushVal() {
      this.addItem.map(i=>{
        i.isDisable = true
      })
    },
    editRowEvent (row) {
      const $table = this.$refs.xTable
      $table.setActiveRow(row)
    },
    removeEvent(row) {
      this.box = "";
      this.$bvModal.msgBoxConfirm("Please confirm that you want to delete .", {
        title: "Handle",
        size: "sm",
        okVariant: "primary",
        okTitle: "Yes",
        cancelTitle: "No",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      })
      .then((value) => {
        if (value) {
          this.box = value;
          this.$refs.xTable.remove(row);
          let obj = {}
          obj.itemDesc = row.name
          obj.itemCode = row.item_code
          obj.coo = row.origin_country
          obj.qty = row.qty
          this.addItem.push(obj)
          this.item.pop(obj)
          this.pushVal()
        }
      });
    },
    saveRowEvent () {
      const $table = this.$refs.xTable
      $table.clearActived().then(() => {
        setTimeout(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Update Success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        }, 300)
      })
    },
    cancelRowEvent (row) {
      const $table = this.$refs.xTable
      $table.clearActived().then(() => {
        // 还原行数据
        $table.revertData(row)
      })
    },
    getSelectionEvent (row) {
      if (row.records.length > 0) {
        this.isShowBtn = false
        row.records.map(i=>{
          i.isDisable = false
        })
        let arr = []
        arr = this.addItem.filter(x => !row.records.includes(x))
        arr.map(i=>{
          i.isDisable = true
        })
      } else {
        this.isShowBtn = true
        this.addItem.map(i=>{
          i.isDisable = true
        })
      }
      const $table = this.$refs.xTable1
      this.selectRecords = $table.getCheckboxRecords()
    },
    addItems() {
      // console.log(this.selectRecords)
      this.selectRecords.map(i=>{
        if (!i.isDisable) {
          if (i.qty == null || i.qty.trim() == ''){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'QTY can not be empty',
                icon: 'XIcon',
                variant: 'danger',
              },
            })
            this.isShow = true
            return
          }
        }
        this.addItem = this.addItem.filter(x => !this.selectRecords.includes(x))
        // console.log(this.addItem)

        const obj = {}
        obj.name = i.itemDesc
        obj.item_code = i.itemCode
        obj.origin_country = i.coo
        obj.qty = i.qty
        this.item.push(obj)
        this.isShow = false
      })
    },
    queryCustomer() {
      // this.$http.get('/admin/settings/organization/findAllOrganization').then(res => {
      //   this.customerList = res.data.data
      // })
      this.$http.get('/api/foms/clientCode/list').then(res => {
        this.customerList = res.data.data
      })
    },
    selCustomer(d) {
      this.customer = d ? d.clientCode : null
      this.createOrderInfo.merchant_code = d ? d.clientCode : null
      this.warehouse = null
      this.provider = null
      if (d) {
        this.queryWarehouse()
        this.queryProvider()
        this.getSKUItem(d.clientCode)
      }
    },
    queryOrderType() {
      this.$http.get('/masterData/findAllByDataType', { params: { data_type: 'orderType'}}).then(res => {
        this.orderTypeList = res.data.data
      })
    },
    selOrderType(d) {
      this.orderType = d ? d.codeDescription : null
      this.createOrderInfo.order_type = d ? d.codeName : null
    },
    queryShipmentTerm() {
      this.$http.get('/masterData/findAllByDataType', { params: { data_type: 'shipmentTerm'}}).then(res => {
        this.shipmentTermList = res.data.data
      })
    },
    selShipmentTerm(d) {
      this.shipmentTerm = d ? d.codeDescription : null
      this.createOrderInfo.shipment_term = d ? d.codeName : null
    },
    selPayMethod(d) {
      if (d) {
        this.createOrderInfo.payment_method = d.key
        this.payMethod = `${d.key}(${d.des})`
      } else {
        this.createOrderInfo.payment_method = null
        this.payMethod = null
      }
    },
    queryCountry() {
      this.$http.get('/masterData/findAllByDataType', { params: { data_type: 'country'}}).then(res => {
        this.countryList = res.data.data
      })
    },
    selCountry1(d) {
      this.country1 = d ? d.codeDescription : null
      this.createOrderInfo.sender.country_code = d ? d.codeName : null
    },
    selCountry2(d) {
      this.country2 = d ? d.codeDescription : null
      this.createOrderInfo.receiver.country_code = d ? d.codeName : null
    },
    queryWarehouse() {
      this.$http.get(`/api/foms/warehouse/list?clientCode=${this.customer}`).then(res => {
        this.warehouseList = res.data.data
      })
    },
    selWarehouse(d) {
      this.warehouse = d ? d.warehouseCode : null
      this.createOrderInfo.warehouse_code = d ? d.warehouseCode : null
    },
    queryProvider() {
      this.$http.get(`/api/foms/logisticsProvider/list?clientCode=${this.customer}`).then(res => {
        this.providerList = res.data.data
      })
    },
    selProvider(d) {
      this.provider = d ? d.serviceCode : null
      this.createOrderInfo.logistics_provider.code = d ? d.serviceCode : null
    },
    getSKUItem(d) {
      this.$http.get(`/api/foms/sku/list?clientCode=${d}`).then(res => {
        this.addItem = res.data.data
        this.pushVal()
      })
    },
    onCreateOrder() {
      const $table = this.$refs.xTable
      // console.log($table.tableData)
      this.createOrderInfo.items = $table.tableData
      this.$http.post('/api/foms/order/create', this.createOrderInfo).then(res => {
        if (res.data.code == '200') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Create Success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$emit('createModel', this.isCreateModel)
        }
      })
    },
    validationForm() {
      this.$refs.rules.validate().then(success => {
        if (success) {
          this.onCreateOrder()
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Please check the data',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          return false
        }
        return true
      })
    },
    handleSearch(keyWord) {
      if (keyWord !== '') {
        let arr = []
        for (let i=0;i<this.addItem.length;i++){
          if (this.addItem[i].itemDesc.split(keyWord).length>1
            || this.addItem[i].itemCode.split(keyWord).length>1) {
            arr.push(this.addItem[i])
          }
        }
        this.addItem = arr
      } else {
        this.getSKUItem()
      }
    },
  },
}
</script>
<style scoped>
.ll-cardHead{
  justify-content: flex-start;
}
.ll-primary{
  color: #ed6d01;
}
.ll-textAlignRight{
  text-align: right;
}
</style>
