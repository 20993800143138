var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('swiper',{staticClass:"swiper-navigations",attrs:{"options":_vm.swiperOptions}},[_vm._l((_vm.swiperData),function(item,index){return _c('swiper-slide',{key:index,staticClass:"rounded swiper-shadow"},[_c('div',{staticClass:"ll-orderBox mb-2"},[_vm._v(_vm._s(item.orderNo))]),_c('vxe-table',{ref:"xTable",refInFor:true,staticClass:"ll-vexTable",attrs:{"border":"","show-overflow":"","keep-source":"","max-height":"400","data":item.itemList,"edit-config":{ trigger: 'manual', mode: 'row', showStatus: true },"empty-text":_vm.$t('No matching records found')}},[_c('vxe-table-column',{attrs:{"field":"itemName","title":_vm.$t('Item')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-form-input',{model:{value:(row.itemName),callback:function ($$v) {_vm.$set(row, "itemName", $$v)},expression:"row.itemName"}})]}}],null,true)}),_c('vxe-table-column',{attrs:{"field":"itemCode","title":"SKU"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-form-input',{model:{value:(row.itemCode),callback:function ($$v) {_vm.$set(row, "itemCode", $$v)},expression:"row.itemCode"}})]}}],null,true)}),_c('vxe-table-column',{attrs:{"field":"qty","title":"QTY"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-form-input',{attrs:{"type":"number"},model:{value:(row.qty),callback:function ($$v) {_vm.$set(row, "qty", $$v)},expression:"row.qty"}})]}}],null,true)}),_c('vxe-table-column',{attrs:{"field":"unitPrice","title":_vm.$t('Price')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-form-input',{attrs:{"type":"number"},model:{value:(row.unitPrice),callback:function ($$v) {_vm.$set(row, "unitPrice", $$v)},expression:"row.unitPrice"}})]}}],null,true)}),_c('vxe-table-column',{attrs:{"field":"currency","title":_vm.$t('Currency')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-form-input',{model:{value:(row.currency),callback:function ($$v) {_vm.$set(row, "currency", $$v)},expression:"row.currency"}})]}}],null,true)}),_c('vxe-table-column',{attrs:{"field":"coo","title":_vm.$t('Origin')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-form-input',{model:{value:(row.coo),callback:function ($$v) {_vm.$set(row, "coo", $$v)},expression:"row.coo"}})]}}],null,true)}),_c('vxe-table-column',{attrs:{"field":"udf","title":"UDF"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-form-input',{model:{value:(row.udf),callback:function ($$v) {_vm.$set(row, "udf", $$v)},expression:"row.udf"}})]}}],null,true)}),_c('vxe-table-column',{attrs:{"field":"batchCode","title":_vm.$t('Batch No')+'.'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-form-input',{model:{value:(row.batchCode),callback:function ($$v) {_vm.$set(row, "batchCode", $$v)},expression:"row.batchCode"}})]}}],null,true)}),_c('vxe-table-column',{attrs:{"field":"expiryDay","title":_vm.$t('Expiry Day')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-form-input',{model:{value:(row.expiryDay),callback:function ($$v) {_vm.$set(row, "expiryDay", $$v)},expression:"row.expiryDay"}})]}}],null,true)}),_c('vxe-table-column',{attrs:{"field":"condition","title":_vm.$t('Condition')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-form-input',{model:{value:(row.condition),callback:function ($$v) {_vm.$set(row, "condition", $$v)},expression:"row.condition"}})]}}],null,true)})],1)],1)}),_c('div',{staticClass:"swiper-button-next",attrs:{"slot":"button-next"},slot:"button-next"}),_c('div',{staticClass:"swiper-button-prev",attrs:{"slot":"button-prev"},slot:"button-prev"})],2),_c('div',{staticClass:"d-flex justify-content-end mt-1"},[_c('b-button',{staticClass:"btn",attrs:{"variant":"primary"},on:{"click":_vm.done}},[_vm._v(" "+_vm._s(_vm.$t('Update'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }