<template>
  <div>
    <div class="ll-box d-flex">
      <!-- breadcrumb -->
      <div class="breadcrumb-top">
        <b-breadcrumb class="">
          <b-breadcrumb-item :to="{ name: 'dashboard' }">
            <feather-icon
              icon="HomeIcon"
              size="16"
            />
          </b-breadcrumb-item>
          <b-breadcrumb-item>
            {{ $t('Fulfillment') }}
          </b-breadcrumb-item>
          <b-breadcrumb-item active >
            {{ $t('Sales Order') }}
          </b-breadcrumb-item>
        </b-breadcrumb>
      </div>
      <!-- button -->
      <b-row class="mb-1 justify-content-end">
        <b-col cols="2" class="d-flex justify-content-end">
          <b-button
            variant="primary"
            class="mr-1"
            v-b-modal.modal-upload
          >
            Upload
          </b-button>
          <b-button
            variant="primary"
            v-b-modal.modal-createOrder
          >
            Create
          </b-button>
        </b-col>
      </b-row>
    </div>
    <!-- Filters -->
    <b-card no-body>
      <b-card-body>
        <b-row class="mb-1">
          <b-col cols="2">
            <b-input-group class="input-group-merge ml-1 w-100">
              <b-input-group-prepend is-text>
                <feather-icon
                  icon="SearchIcon"
                  class="text-muted"
                />
              </b-input-group-prepend>
              <b-form-input
                placeholder="Search..."
                v-b-modal.modal-search
                :readonly="true"
                class="ll-readonlyInput"
              />
            </b-input-group>
          </b-col>
          <b-col cols="2">
            <v-select
              v-model="status"
              :options="statusList"
              label="codeDescription"
              @input="selStatus"
              placeholder="Status"
            ></v-select>
          </b-col>
          <b-col cols="2">
            <flat-pickr
              v-model="time"
              class="form-control ll-fpickr"
              :config="dateConfig"
              placeholder="Status Date Range"
              @on-change="onChange"
            />
            <!-- <el-date-picker
              v-model="time"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="-"
              :picker-options="pickerOptions"
              size="small"
              class="ll-datePicker"
              @input="onDateHandle"
            >
            </el-date-picker> -->
          </b-col>
          <b-col cols="2">
            <b-button
              variant="info"
              class="round mr-1"
              @click="getList"
            >
              {{ $t('Search') }}
            </b-button>
            <b-button
              variant="secondary"
              class="round"
              v-show="isResetBtn"
              @click="onReset"
            >
              {{ $t('Reset') }}
            </b-button>
          </b-col>
          <b-col cols="4" class="d-flex justify-content-end">
            <b-button
              variant="outline-warning"
              class="mr-1"
              :style="{'cursor': isShowBtn ? 'no-drop' : 'pointer'}"
              :disabled="isShowBtn"
              v-b-modal.modal-delivery
            >
              Edit Delivery
            </b-button>
            <b-button
              variant="outline-warning"
              class="mr-1"
              :style="{'cursor': isShowBtn ? 'no-drop' : 'pointer'}"
              :disabled="isShowBtn"
              v-b-modal.modal-lineItems
            >
              Edit line items
            </b-button>
            <!-- <b-button
            variant="warning"
            v-b-modal.modal-export
            v-if="export1"
            >
              {{$t('Export')}}
            </b-button> -->
            <b-dropdown
              :text="$t('Export')"
              variant="warning"
              v-b-modal.modal-exportPro
              v-if="export2"
              class="ll-dropdown"
            >
              <b-dropdown-item>Export new report<br>Check export status</b-dropdown-item>
            </b-dropdown>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <!-- status -->
    <b-badge
      pill
      class="ll-badge mb-1 mr-1 cursor-pointer"
      variant="light-info"
      v-for="(item, index) in paymentStatusList"
      :key="index"
      @click="onStatusSearch(item.name)"
    >
      {{(item.name !== null) ? item.name.replace(/_/g, ' ') : null}}
      <span class="ll-count">{{item.count}}</span>
    </b-badge>
    <!-- table -->
    <b-table
    ref="selectableTable"
    class="position-relative ll-table"
    :items="tableData"
    :fields="columns"
    primary-key="id"
    show-empty
    empty-text="No matching records found"
    selectable
    @row-selected="onRowSelected"
    :select-mode="selectMode"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    :sort-direction="sortDirection"
    :sticky-header="true"
    :striped="true"
    :hover="true"
    :busy="isBusy"
    >
        <template #head(selected)="data">
            <b-form-checkbox @input="selectAllRowsData">
                </b-form-checkbox>
        </template>
          <template #head(type)="data">
            <el-tooltip placement="top" effect="light">
              <div slot="content">
                <div class="mb-1">
                  <svg aria-hidden="true" class="ll-normal" >
                    <use xlink:href="#icon-image-_2_"></use>
                  </svg>
                  <span class="ml-1">Normal Order</span>
                </div>
                <div class="mb-1">
                  <svg aria-hidden="true" class="ll-issue" >
                    <use xlink:href="#icon-image-_2_"></use>
                  </svg>
                  <span class="ml-1">Order width issue</span>
                </div>
                <div class="mb-1">
                  <svg aria-hidden="true" class="ll-normal" >
                    <use xlink:href="#icon-image-_1_"></use>
                  </svg>
                  <span class="ml-1">Normal split order</span>
                </div>
                <div class="mb-1">
                  <svg aria-hidden="true" class="ll-issue" >
                    <use xlink:href="#icon-image-_1_"></use>
                  </svg>
                  <span class="ml-1">Split order with issue</span>
                </div>
              </div>
              <feather-icon
                icon="HelpCircleIcon"
                size="24"
              />
            </el-tooltip>
        </template>
        <template #cell(selected)="{rowSelected, index}">
            <b-form-checkbox :checked="rowSelected?true:false" @change="selectRowData(rowSelected, index)">
            </b-form-checkbox>
        </template>
        <template #cell(type)='row'>
          <svg aria-hidden="true"
            class="ll-normal"
            v-if="(row.item.orderIssue == null) && (row.item.splittedParent == null) && (row.item.splittedChildren == null)"
          >
            <use xlink:href="#icon-image-_2_"></use>
          </svg>
          <el-tooltip placement="top" effect="light" v-else>
            <template slot="content">
              <div class="ll-issueBox"
                v-if="(row.item.orderIssue !== null) && (row.item.splittedParent == null) && (row.item.splittedChildren == null)"
              >
                <feather-icon
                  icon="AlertCircleIcon"
                  size="24"
                />
                <span>Please finish the shipping address</span>
              </div>
              <div class=""
                v-if="(row.item.orderIssue == null) && (row.item.splittedParent !== null) && (row.item.splittedChildren !== null)"
              >
                <svg aria-hidden="true" class="ll-normal1">
                  <use xlink:href="#icon-image-_1_"></use>
                </svg>
                <span>Original order of {{row.item.orderNo}}</span>
              </div>
              <div class=""
                v-if="(row.item.orderIssue !== null) && (row.item.splittedParent !== null) && (row.item.splittedChildren !== null)"
              >
                <svg aria-hidden="true" class="ll-normal1">
                  <use xlink:href="#icon-image-_1_"></use>
                </svg>
                <span>Split from {{row.item.orderNo}}</span>
              </div>
            </template>
            <svg aria-hidden="true"
              :class="(row.item.orderIssue !== null) ? 'll-issue':'ll-normal'"
              v-if="(row.item.splittedParent == null && row.item.splittedChildren == null)"
            >
              <use xlink:href="#icon-image-_2_"></use>
            </svg>
            <svg aria-hidden="true"
              :class="(row.item.orderIssue !== null) ? 'll-issue':'ll-normal'"
              v-if="(row.item.splittedParent !== null && row.item.splittedChildren !== null)">
              <use xlink:href="#icon-image-_1_"></use>
            </svg>
          </el-tooltip>
        </template>
        <template #cell(orderNo)="row">
          <b-link
            class="nav-link"
            @click="onOrderDetail(row.item.id)"
            style="padding-left: 0px;"
          >
          {{row.item.orderNo}}
          </b-link>
        </template>
        <template #cell(totalGoodsValue)="row">
          <span v-if="(row.item.goodsValueCurrency==='JPY' || row.item.goodsValueCurrency==='VND' || row.item.goodsValueCurrency==='IDR' || row.item.goodsValueCurrency==='KRW')">{{row.item.goodsValueCurrency}} {{ row.item.totalGoodsValue.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</span>
          <span v-else>{{row.item.goodsValueCurrency}} {{ row.item.totalGoodsValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</span>
        </template>
          <template #cell(statusCode)="row">
          <b-badge
            pill
            class="ll-badge"
            variant="light-info"
            v-if="row.item.statusCode !== null"
          >
            {{(row.item.statusCode !== null) ? row.item.statusCode.replace(/_/g, ' ') : null}}
          </b-badge>
        </template>
        <template #cell(action)="row">
            <el-dropdown trigger="click" @command="handleCommand">
              <span class="el-dropdown-link">
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="row.item" v-b-modal.modal-orderDetail>
                  <feather-icon icon="EyeIcon" />
                  <span class="align-middle ml-50">View</span>
                </el-dropdown-item>
                <!-- <el-dropdown-item v-b-modal.modal-splitOrder :command="scope.row">
                  <svg aria-hidden="true" class="ll-icon">
                    <use xlink:href="#icon-image-_1_"></use>
                  </svg>
                  <span class="align-middle ml-50">Split Order</span>
                </el-dropdown-item> -->
                <el-dropdown-item v-b-modal.modal-del :command="row.item">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Delete</span>
                </el-dropdown-item>
                <el-dropdown-item :command="row.item.id">
                  <svg aria-hidden="true" class="ll-icon-small">
                    <use xlink:href="#icon-Waybill"></use>
                  </svg>
                  <span class="align-middle ml-50">Print Label</span>
                </el-dropdown-item>
                <!-- <el-dropdown-item :command="scope.row">
                  <feather-icon icon="FileTextIcon" />
                  <span class="align-middle ml-50">Print Invoice</span>
                </el-dropdown-item> -->
                <el-dropdown-item command="track">
                  <svg aria-hidden="true" class="ll-icon-small">
                    <use xlink:href="#icon-peisongguanli"></use>
                  </svg>
                  <span class="align-middle ml-50">Track and Trace</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
        </template>
    </b-table>
    <!-- table -->
    <!-- <el-table
      :data="tableData"
      stripe
      size="small"
      ref="table"
      :empty-text="$t('No matching records found')"
      @filter-change="filterTHeader"
      @sort-change="onSortChange"
      @selection-change="handleSelection"
    >
      <el-table-column
        type="selection"
        width="55">
      </el-table-column>
      <template v-for="(item, index) in tableHeaders">
        <el-table-column
          v-if="item.prop == 'isOrderWithIssue'"
          :key="index"
          :label="item.label"
          :prop="item.prop"
          :width="item.width"
        >
          <template slot="header">
            <el-tooltip placement="top" effect="light">
              <div slot="content">
                <div class="mb-1">
                  <svg aria-hidden="true" class="ll-normal" >
                    <use xlink:href="#icon-image-_2_"></use>
                  </svg>
                  <span class="ml-1">Normal Order</span>
                </div>
                <div class="mb-1">
                  <svg aria-hidden="true" class="ll-issue" >
                    <use xlink:href="#icon-image-_2_"></use>
                  </svg>
                  <span class="ml-1">Order width issue</span>
                </div>
                <div class="mb-1">
                  <svg aria-hidden="true" class="ll-normal" >
                    <use xlink:href="#icon-image-_1_"></use>
                  </svg>
                  <span class="ml-1">Normal split order</span>
                </div>
                <div class="mb-1">
                  <svg aria-hidden="true" class="ll-issue" >
                    <use xlink:href="#icon-image-_1_"></use>
                  </svg>
                  <span class="ml-1">Split order with issue</span>
                </div>
              </div>
              <feather-icon
                icon="HelpCircleIcon"
                size="24"
              />
            </el-tooltip>
          </template>
          <template slot-scope="scope">
            <svg aria-hidden="true"
              class="ll-normal"
              v-if="(scope.row.orderIssue == null) && (scope.row.splittedParent == null) && (scope.row.splittedChildren == null)"
            >
              <use xlink:href="#icon-image-_2_"></use>
            </svg>
            <el-tooltip placement="top" effect="light" v-else>
              <template slot="content">
                <div class="ll-issueBox"
                  v-if="(scope.row.orderIssue !== null) && (scope.row.splittedParent == null) && (scope.row.splittedChildren == null)"
                >
                  <feather-icon
                    icon="AlertCircleIcon"
                    size="24"
                  />
                  <span>Please finish the shipping address</span>
                </div>
                <div class=""
                  v-if="(scope.row.orderIssue == null) && (scope.row.splittedParent !== null) && (scope.row.splittedChildren !== null)"
                >
                  <svg aria-hidden="true" class="ll-normal1">
                    <use xlink:href="#icon-image-_1_"></use>
                  </svg>
                  <span>Original order of {{scope.row.orderNo}}</span>
                </div>
                <div class=""
                  v-if="(scope.row.orderIssue !== null) && (scope.row.splittedParent !== null) && (scope.row.splittedChildren !== null)"
                >
                  <svg aria-hidden="true" class="ll-normal1">
                    <use xlink:href="#icon-image-_1_"></use>
                  </svg>
                  <span>Split from {{scope.row.orderNo}}</span>
                </div>
              </template>
              <svg aria-hidden="true"
                :class="(scope.row.orderIssue !== null) ? 'll-issue':'ll-normal'"
                v-if="(scope.row.splittedParent == null && scope.row.splittedChildren == null)"
              >
                <use xlink:href="#icon-image-_2_"></use>
              </svg>
              <svg aria-hidden="true"
                :class="(scope.row.orderIssue !== null) ? 'll-issue':'ll-normal'"
                v-if="(scope.row.splittedParent !== null && scope.row.splittedChildren !== null)">
                <use xlink:href="#icon-image-_1_"></use>
              </svg>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          v-else-if="item.prop == 'orderNo'"
          :key="index"
          :label="item.label"
          :prop="item.prop"
          :width="item.width"
          sortable
        >
          <template slot-scope="scope">
            <b-link
              class="nav-link"
              @click="onOrderDetail(scope.row.id)"
              style="padding-left: 0px;"
            >
            {{scope.row.orderNo}}
            </b-link>
          </template>
        </el-table-column>
        <el-table-column
          v-else-if="item.prop == 'totalGoodsValue'"
          :key="index"
          :label="item.label"
          :prop="item.prop"
          :width="item.width"
        >
          <template slot-scope="scope">
            <span v-if="(scope.row.goodsValueCurrency==='JPY' || scope.row.goodsValueCurrency==='VND' || scope.row.goodsValueCurrency==='IDR' || scope.row.goodsValueCurrency==='KRW')">{{scope.row.goodsValueCurrency}} {{ scope.row.totalGoodsValue.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</span>
            <span v-else>{{scope.row.goodsValueCurrency}} {{ scope.row.totalGoodsValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-else-if="item.prop == 'statusCode'"
          :key="index"
          :label="item.label"
          :prop="item.prop"
          :width="item.width"
          sortable
        >
          <template slot-scope="scope">
            <b-badge
              pill
              class="ll-badge"
              variant="light-info"
              v-if="scope.row.statusCode !== null"
            >
              {{(scope.row.statusCode !== null) ? scope.row.statusCode.replace(/_/g, ' ') : null}}
            </b-badge>
          </template>
        </el-table-column>
        <el-table-column
          v-else-if="item.prop == 'Actions'"
          :key="index"
          :label="item.label"
          :prop="item.prop"
          :width="item.width"
        >
          <template slot-scope="scope">
            <el-dropdown trigger="click" @command="handleCommand">
              <span class="el-dropdown-link">
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="scope.row" v-b-modal.modal-orderDetail>
                  <feather-icon icon="EyeIcon" />
                  <span class="align-middle ml-50">View</span>
                </el-dropdown-item>
                <el-dropdown-item v-b-modal.modal-del :command="scope.row">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Delete</span>
                </el-dropdown-item>
                <el-dropdown-item :command="scope.row.id">
                  <svg aria-hidden="true" class="ll-icon-small">
                    <use xlink:href="#icon-Waybill"></use>
                  </svg>
                  <span class="align-middle ml-50">Print Label</span>
                </el-dropdown-item>
                <el-dropdown-item command="track">
                  <svg aria-hidden="true" class="ll-icon-small">
                    <use xlink:href="#icon-peisongguanli"></use>
                  </svg>
                  <span class="align-middle ml-50">Track and Trace</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
        <el-table-column
          v-else
          :key="index"
          :prop="item.prop"
          :label="item.label"
          :column-key="item.prop"
          :min-width="item.minWidth"
          :width="item.width"
          show-overflow-tooltip
          sortable
        >
        </el-table-column>
      </template>
    </el-table> -->
    <div class="d-flex justify-content-center flex-wrap ll-page">
      <div class="d-flex align-items-center mb-0 mt-1 mr-1">
        <b-form-select
          v-model="size"
          :options="pageOptions"
          class="mx-1"
          @input="handlePageChange"
        />
        <span class="text-nowrap"> item per page </span>
      </div>
      <div class="mt-1">
        <b-pagination
          v-model="page"
          :total-rows="totalSize"
          :per-page="size"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
          @change="handleChangePage"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </div>
    <!-- <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
    <el-checkbox-group v-model="checkedFilter" @change="handleChecked">
      <el-checkbox v-for="(item, index) in filters" :label="item.value" :key="index">{{item.text}}</el-checkbox>
    </el-checkbox-group> -->
    <!-- search -->
    <b-modal
      id="modal-search"
      cancel-variant="outline-secondary"
      ok-title="Search"
      centered
      title="Search"
      v-model="searchModal"
    >
      <b-form>
        <b-form-group>
          <label >{{$t('Order')}}#:</label>
          <b-form-input v-model="condition.orderNumber"/>
        </b-form-group>
        <b-form-group>
          <label >{{$t('Customer')}}</label>
          <b-form-input v-model="condition.clientCode"/>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <b-button
          variant="primary"
          class="btn"
          @click="getList"
        >
          {{ $t('Search') }}
        </b-button>
      </template>
    </b-modal>
    <!-- upload -->
    <b-modal
      id="modal-upload"
      cancel-variant="outline-secondary"
      ok-title="Sure"
      centered
      title=""
      v-model="show"
    >
      <el-upload
        class="upload-demo ll-upload"
        drag
        action="https://jsonplaceholder.typicode.com/posts/"
        accept=".csv,.xlsx"
        :on-success="uploadSuccess"
        :on-error="uploadFail"
        :on-progress="uploadPro"
        ref="upload"
        :auto-upload="false"
        :limit="1">
        <!-- <i class="el-icon-upload"></i> -->
        <div class="el-upload__text" v-if="isDefault">
          {{uploadText}}
        </div>
        <div v-if="uLoading" class="el-upload__text">
          <p class="ll-uploadText">Uploading...</p>
          <b-spinner label="Loading..." />
        </div>
        <div v-if="uSuccess" class="el-upload__text">
          <svg aria-hidden="true" class="ll-success ll-uploadIcon">
            <use xlink:href="#icon-form_icon_normal"></use>
          </svg><br>
          <span class="ll-uploadText ll-success">Upload success</span>
        </div>
        <div v-if="uFail" class="el-upload__text ll-upload">
          <svg aria-hidden="true" class="ll-fail ll-uploadIcon">
            <use xlink:href="#icon-delete"></use>
          </svg><br>
          <span class="ll-uploadText ll-fail">Upload failed</span><br>
          <span class="ll-uploadText">ERROR: SKU {XXX} not found</span>
        </div>
        <div class="el-upload__tip" slot="tip">
          <b-link @click="downTem">Download template</b-link> here<br>
          Only accept CSV,xlsx etc
        </div>
      </el-upload>
      <template #modal-footer>
        <b-button
          variant="outline-secondary"
          class="btn"
          @click="show = false"
          v-if="false"
        >
          {{ $t('Cancel') }}
        </b-button>
        <b-button
          variant="primary"
          class="btn"
          @click="onUpload"
        >
          {{ $t('Upload') }}
        </b-button>
      </template>
    </b-modal>
    <!-- Export -->
    <b-modal
      id="modal-export"
      cancel-variant="outline-secondary"
      ok-title=""
      centered
      title=""
      v-model="show1"
    >
      <div class="ll-ulBox">
        <span>Export to:</span>
        <ul>
          <li class="mb-1">
            <b-form-radio
              v-model="selected1"
              name="some-radios"
              value="a"
            >
              CSV
            </b-form-radio>
          </li>
          <li>
            <b-form-radio
              v-model="selected1"
              name="some-radios"
              value="b"
            >
              xlsx
            </b-form-radio>
          </li>
        </ul>
      </div>
      <div class="ll-ulBox">
        <span>Orders:</span>
        <ul>
          <li class="mb-1">
            <b-form-radio
              v-model="selected2"
              name="some-radios2"
              value="c"
            >
              All orders
            </b-form-radio>
          </li>
          <li>
            <b-form-radio
              v-model="selected2"
              name="some-radios2"
              value="d"
            >
              Selected<br>(Filtered with xxx,xxx,xxx)
            </b-form-radio>
          </li>
        </ul>
      </div>
      <div class="ll-ulBox">
        <span>Type:</span>
        <ul>
          <li class="mb-1">
            <b-form-radio
              v-model="selected3"
              name="some-radios3"
              value="e"
            >
              Order information
            </b-form-radio>
          </li>
          <li>
            <b-form-radio
              v-model="selected3"
              name="some-radios3"
              value="f"
            >
              Order with line items details
            </b-form-radio>
          </li>
        </ul>
      </div>
      <template #modal-footer>
        <b-button
          variant="outline-secondary"
          class="btn"
          @click="show1 = false"
        >
          {{ $t('Cancel') }}
        </b-button>
        <b-button
          variant="primary"
          class="btn"
          @click="exportHandle"
        >
          {{ $t('Export') }}
        </b-button>
      </template>
    </b-modal>
    <!-- Export progress-->
    <b-modal
      id="modal-exportPro"
      cancel-variant="outline-secondary"
      ok-title=""
      centered
      title=""
      v-model="show2"
      size="xl"
    >
      <b-table :items="items" :fields="fields"
        :borderless='false' :hover="true"
        :sticky-header="true"
        class="ll-table"
      >
        <template #cell(action)="scope">
          <b-button
            class="btn-icon"
            variant="flat-danger"
            @click="del(scope.item.id)"
          >
            <feather-icon icon="XIcon" />
          </b-button>
          <b-button
            class="btn-icon"
            variant="flat-info"
            :href="scope.item.reportUrl"
            :disabled="scope.item.reportUrl===null"
            :style="{'cursor': (scope.item.reportUrl===null) ? 'no-drop' : 'pointer'}"
          >
            <feather-icon icon="ArrowDownIcon" />
          </b-button>
        </template>
        <template #cell(progress)="scope">
          <b-progress
            key="info"
            :max="scope.item.maxProgress"
            variant="info"
            class="progress-bar-info"
          >
            <b-progress-bar
              :value="scope.value"
              variant="info"
              :label="`${((scope.item.progress/scope.item.maxProgress) * 100)}%`"
            />
          </b-progress>
        </template>
      </b-table>
      <template #modal-footer>
        <b-button
          variant="outline-secondary"
          class="btn"
          @click="show2 = false"
        >
          {{ $t('Cancel') }}
        </b-button>
      </template>
    </b-modal>
    <!-- delete-->
    <b-modal
      id="modal-del"
      cancel-variant="outline-secondary"
      ok-title=""
      centered
      title=""
      v-model="show3"
    >
      <div class="text-center">
        <svg aria-hidden="true" class="ll-delicon">
          <use xlink:href="#icon-delete"></use>
        </svg><p/>
        <p style="font-size:18px;"><b>Delete Order</b></p>
        <p>You are deleting an orders<br>
          <b>This action cannot be undone</b>
        </p>
        <!-- <span>Type 'delete order' to confirm</span>
        <b-form-input v-model="delOrder"/> -->
      </div>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-center">
          <b-button
            variant="outline-secondary"
            class="btn mr-2"
            @click="show3 = false"
          >
            {{ $t('Cancel') }}
          </b-button>
          <b-button
            variant="primary"
            class="btn"
            @click="onDelOrder"
          >
            {{ $t('Confirm') }}
          </b-button>
        </div>
      </template>
    </b-modal>
    <!-- Split order-->
    <b-modal
      id="modal-splitOrder"
      cancel-variant="outline-secondary"
      ok-title=""
      centered
      :title="'Split Order / '+ orderNumber"
      v-model="show4"
      size="xl"
    >
      <el-table
        :data="splitOrderList"
        @selection-change="handleSelectionChange"
        size="small"
        stripe
        :empty-text="$t('No matching records found')"
      >
        <el-table-column
          type="selection"
          width="50">
        </el-table-column>
        <template v-for="(item, index) in orderHeaders">
          <el-table-column
            :key="index"
            :label="item.label"
            :prop="item.prop"
            :width="item.width"
          >
          </el-table-column>
        </template>
      </el-table>
      <template #modal-footer>
        <b-button
          variant="outline-secondary"
          class="btn"
          @click="show4 = false"
        >
          {{ $t('Cancel') }}
        </b-button>
        <b-button
          variant="primary"
          class="btn"
          :disabled="splitBtn"
          :style="{'cursor': splitBtn ? 'no-drop' : 'pointer'}"
        >
          {{ $t('Split') }}
        </b-button>
      </template>
    </b-modal>
    <!-- Edit Delivery -->
    <b-modal
      id="modal-delivery"
      cancel-variant="outline-secondary"
      ok-title=""
      centered
      title="Bulk Edit Order Delivery Information"
      v-model="show5"
      size="xl"
      hide-footer
    >
      <delivery :listResult="listResult" @deliveryModel="listenDeliveryModel"></delivery>
      <!-- <template #modal-footer>
        <b-button
          variant="outline-secondary"
          class="btn"
          @click="show5 = false"
        >
          {{ $t('Cancel') }}
        </b-button>
        <b-button
          variant="primary"
          class="btn"
        >
          {{ $t('Save all') }}
        </b-button>
      </template> -->
    </b-modal>
    <!-- Edit line items -->
    <b-modal
      id="modal-lineItems"
      cancel-variant="outline-secondary"
      ok-title=""
      centered
      title="Bulk Edit Order Line Items"
      size="xl"
      v-model="show6"
      hide-footer
    >
      <line-items :listResult="listResult" @lineModel="listenLineModel"></line-items>
      <!-- <template #modal-footer>
        <b-button
          variant="primary"
          class="btn"
        >
          {{ $t('Done') }}
        </b-button>
      </template> -->
    </b-modal>
    <!-- Create Order -->
    <b-modal
      id="modal-createOrder"
      cancel-variant="outline-secondary"
      ok-title=""
      centered
      title="Sales Order / Create Order"
      size="xl"
      v-model="show7"
      hide-footer
    >
      <create-sales @createModel="listenCreateModel"></create-sales>
      <!-- <template #modal-footer>
        <b-button
          variant="outline-secondary"
          class="btn"
          @click="show7 = false"
        >
          {{ $t('Cancel') }}
        </b-button>
        <b-button
          variant="primary"
          class="btn"
        >
          {{ $t('Create') }}
        </b-button>
      </template> -->
    </b-modal>
    <!-- Order Detail -->
    <b-modal
      id="modal-orderDetail"
      ref="modal-orderDetail"
      cancel-variant="outline-secondary"
      ok-title=""
      centered
      title=""
      size="xl"
      v-model="show8"
    >
      <sales-details :orderNumber="orderNumber" :salesId="salesId"
        @delModel="listenDelModel"
      ></sales-details>
      <template #modal-footer>
        <b-button
          variant="outline-secondary"
          class="btn"
          @click="show8 = false"
        >
          {{ $t('Close') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BButton, BTooltip,
  BCard, BCardBody, BRow, BCol, BSidebar, VBToggle, BTable,BFormCheckbox,
  BInputGroupPrepend, BInputGroup, BBreadcrumb, BBreadcrumbItem,
  BBadge, BSpinner, BDropdown, BDropdownItem, BLink, BModal, VBModal,
  BForm, VBTooltip, BProgress, BProgressBar, BFormRadio,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import Ripple from 'vue-ripple-directive'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import permission from '@core/directives/permission/index.js'
import Delivery from './components/delivery.vue'
import LineItems from './components/lineItems.vue'
import CreateSales from './components/createSales.vue'
import SalesDetails from './components/salesDetails.vue'
import { debounce } from "@/libs/fun.js"
import axios from 'axios'
import store from '@/store/index'
export default {
  components: {
    CreateSales,
    SalesDetails,
    Delivery,
    LineItems,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BTooltip,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BSidebar,
    vSelect,
    flatPickr,
    BTable,
    BInputGroupPrepend,
    BInputGroup,
    BBreadcrumb,
    BBreadcrumbItem,
    BBadge,
    AppCollapse,
    AppCollapseItem,
    BSpinner,
    BDropdown,
    BDropdownItem,
    BLink,
    BModal,
    BForm,
    BProgress,
    BProgressBar,
    BFormRadio,
    BFormCheckbox
  },
  directives: {
    'b-modal': VBModal,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    Ripple,
    permission,
  },
  props: {
  },
  data() {
    return {
      selectMode: 'multi',
      dateConfig: {
        dateFormat: 'Y-m-d',
        mode: 'range',
        defaultDate: '',
      },
      time: '',
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      pageOptions: [50, 100, 200],
      page: null,
      size: 50,
      totalSize: null,
      totalPage: null,
      tableData: [],
      tableHeaders: [
        { label: '', prop: "isOrderWithIssue", width: "60" },
        { label: this.$t('Create Date'), prop: "orderCreationTime", width: "auto" },
        { label: this.$t('Order')+'#', prop: "orderNo", width: "250" },
        { label: this.$t('Customer'), prop: "externalClientCode", width: "auto" },
        { label: this.$t('Source'), prop: "datasourceType", width: "auto" },
        { label: this.$t('Order Value'), prop: "totalGoodsValue", width: "auto" },
        { label: this.$t('Status'), prop: "statusCode", width: "200" },
        { label: this.$t('Last Update'), prop: "orderUpdateTime", width: "auto" },
        { label: this.$t('Action'), prop: "Actions", width: "80" },
      ],
      arr1: [
        { label: '', prop: "isOrderWithIssue", width: "60" },
        { label: this.$t('Create Date'), prop: "orderCreationTime", width: "auto" },
        { label: this.$t('Order')+'#', prop: "orderNo", width: "250" },
        { label: this.$t('Customer'), prop: "externalClientCode", width: "auto" },
        { label: this.$t('Source'), prop: "datasourceType", width: "auto" },
        { label: this.$t('Order Value'), prop: "totalGoodsValue", width: "auto" },
        { label: this.$t('Status'), prop: "statusCode", width: "200" },
        { label: this.$t('Last Update'), prop: "orderUpdateTime", width: "auto" },
        { label: this.$t('Action'), prop: "Actions", width: "80" },
      ],
      filters: [
        // { text: this.$t('Show all'), value: "" },
        { text: this.$t('Create Date'), value: "orderCreationTime" },
        { text:  this.$t('Order')+'#', value: "orderNo" },
        { text: this.$t('Customer'), value: "externalClientCode" },
        { text: this.$t('Source'), value: "datasourceType" },
        { text: this.$t('Order Value'), value: "totalGoodsValue" },
        { text: this.$t('Status'), value: "statusCode" },
        { text: this.$t('Last Update'), value: "orderUpdateTime" },
        //{ text: "Actions", value: "Actions" },
      ],
      columns:[
        { key: 'selected', label: 'selected'},
        { label: '', key: "type"},
        { label: this.$t('Create Date'), key: "orderCreationTime", sortable: true },
        { label: this.$t('Order')+'#', key: "orderNo", sortable: true },
        { label: this.$t('Customer'), key: "externalClientCode", sortable: true },
        { label: this.$t('Source'), key: "datasourceType",sortable: true },
        { label: this.$t('Order Value'), key: "totalGoodsValue",},
        { label: this.$t('Status'), key: "statusCode",sortable: true },
        { label: this.$t('Last Update'), key: "orderUpdateTime", sortable: true },
        { label: this.$t('Action'), key: "action",},
      ],
      statusList: [],
      status: '',
      isBusy: true,
      uploadText: 'Upload file here(drag the file or select from computer)',
      isDefault: true,
      uLoading: false,
      uSuccess: false,
      uFail: false,
      show: false,
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      show6: false,
      show7: false,
      show8: false,
      export1: true,
      export2: false,
      items: [],
      fields: [
        { key: 'exportTypes', label: 'Export Name' },
        { key: 'progress', label: 'Export Progress', class: 'll-progress' },
        { key: 'action', label: this.$t('Action') }
      ],
      splitOrderList: [
        {
          itemName: "Mask",
          skuCode: "HHH-123",
          qty: 5,
          unitPrice: 15,
          currency: "HKD",
          origin: "HK",
          userDefinedField: "",
          batchNumber: "121-24",
          expiryDay: "2021-12-10",
          condition: "GOOD"
        }
      ],
      orderHeaders: [
        { label: this.$t('Item'), prop: "itemName", width: "auto" },
        { label: 'SKU', prop: "skuCode", width: "auto" },
        { label: 'QTY', prop: "qty", width: "auto" },
        { label: this.$t('Currency'), prop: "currency", width: "auto" },
        { label: this.$t('Origin'), prop: "origin", width: "auto" },
        { label: 'UDF', prop: "userDefinedField", width: "auto" },
        { label: this.$t('Batch No')+'.', prop: "batchNumber", width: "100" },
        { label: this.$t('Expiry Day'), prop: "expiryDay", width: "100" },
        { label: this.$t('Condition'), prop: "condition", width: "100" },
      ],
      orderNumber: null,
      splitBtn: true,
      isShowBtn: true,
      selected1: '',
      selected2: '',
      selected3: '',
      delOrder: null,
      isShowBtn1: true,
      pickerOptions: {
        shortcuts: [{
          text: 'Last 7 days',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Last 14 days',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 14);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Last 21 days',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 21);
            picker.$emit('pick', [start, end]);
          }
        }/*, {
          text: 'This month',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Last month',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 60);
            end.setTime(end.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }*/]
      },
      windowWidth: document.documentElement.clientWidth,
      isResetBtn: true,
      condition: {
        orderNumber: null,
        clientCode: null,
        latestStatus: null,
        paymentStatus: null,
        dateRangeFrom: null,
        dateRangeTo: null,
        orderBy: 'id',
        sequence: 'desc',
        size: 50,
        page: 1,
      },
      listResult: null,
      checkAll: false,
      checkedFilter: [],
      isIndeterminate: true,
      paymentStatusList: [],
      salesId: null,
      searchModal: false,
      orderNo: null,
      print: 'print',
    }
  },
  computed: {},
  watch: {
    delOrder (val) {
      if (val) {
        this.isShowBtn1 = false
      } else {
        this.isShowBtn1 = true
      }
    },
    windowWidth(val) {
      if (val < 1152) {
        this.isResetBtn = false
      } else {
        this.isResetBtn = true
      }
    },
    // show2(val) {
    //   if (val) {
    //     this.getProcessStatus()
    //     this.timer = setInterval(() => {
    //       setTimeout(() => {
    //         this.getProcessStatus()
    //       }, 0)
    //     }, 5000)
    //   } else {
    //     clearInterval(this.timer)
    //   }
    // }
  },
  created() {
  },
  mounted() {
    this.defaultDate()
    this.getList()
    this.queryStatus()
    window.onresize = () => {
      return (() => {
        window.fullWidth = document.documentElement.clientWidth
        this.windowWidth = window.fullWidth
      })()
    }
  },
  methods: {
    onRowSelected(items) {
      this.listResult = items
      if (items.length > 0) {
        this.isShowBtn = false
      } else {
        this.isShowBtn = true
      }
    },
    selectRowData(rowSelected, index){
        if(rowSelected == false){
            this.$refs.selectableTable.selectRow(index)
        }else{
            this.$refs.selectableTable.unselectRow(index)
        }
        },
    selectAllRowsData(val) {
        if (val == true) {
        this.$refs.selectableTable.selectAllRows()
        }
        if(val == false){
        this.$refs.selectableTable.clearSelected()
        }
        
    },
    formatDate(date) {
      const year = date.getFullYear() + "-"
      const mouth = (date.getMonth()> 8 ? (date.getMonth() + 1) : "0" + (date.getMonth() + 1)) + "-"
      const day = (date.getDate()> 9 ? (date.getDate()) : "0" + (date.getDate()))
      const dateString = year + mouth + day
      return dateString
    },
    defaultDate() {
      let d1 = new Date((new Date).setDate((new Date).getDate() - 2))
      this.condition.dateRangeFrom = this.formatDate(d1) + ' 00:00:00'
      let d2 = new Date()
      this.condition.dateRangeTo = this.formatDate(d2) + ' 23:59:59'
      this.time = [this.condition.dateRangeFrom, this.condition.dateRangeTo]
    },
    onDateHandle(){
      if (this.time) {
        const dd = this.time
        this.condition.dateRangeFrom = this.formatDate(dd[0]) + ' 00:00:00'
        this.condition.dateRangeTo = this.formatDate(dd[1]) + ' 23:59:59'
      } else {
        this.condition.dateRangeFrom = null
        this.condition.dateRangeTo = null
      }
    },
    // 根据日期查询
    onChange(selectedDates, dateStr, instance) {
      if (selectedDates.length == 2) {
        this.condition.dateRangeFrom = this.formatDate2(selectedDates[0]) + ' 00:00:00'
        this.condition.dateRangeTo = this.formatDate2(selectedDates[1]) + ' 23:59:59'
        this.getList()
      }
    },
    formatDate2(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;

      return [year, month, day].join('-');
  },
    getList() {
      // console.log(this.condition)
      this.isBusy = true
      this.$http.post('/api/foms/order/list', this.condition).then(res=>{
        const info = res.data.data
        this.tableData = info.content
        this.page = info.page
        this.size = info.size
        this.totalSize = info.totalSize
        this.totalPage = info.totalPage
        this.getPaymentStatus(info.content)
        this.searchModal = false
        this.isBusy = false
      })
    },
    getPaymentStatus(data) {
      let arr1 = []
      data.map(item=>{
        if (item.statusCode !== null) {
          arr1.push(item.statusCode)
        }
      })
      let obj = {}
      for (let i=0; i<arr1.length;i++){
        let value = arr1[i]
        if (!obj[value]){
          obj[value] = 1
        }else {
          obj[value]++
        }
      }
      let arr2 = []
      for (let j in obj) {
        arr2.push({
          name: j,
          count: obj[j]
        })
      }
      this.paymentStatusList = arr2
    },
    queryStatus(){
      this.$http.get('/masterData/findAllByDataType', { params: { data_type: 'orderStatus1'}}).then(res => {
        this.statusList = res.data.data
      })
    },
    selStatus(d) {
      this.condition.latestStatus = d ? d.codeName : null
      this.status = d ? d.codeDescription : null
    },
    onStatusSearch(s){
      this.condition.paymentStatus = s
      this.getList()
    },
    filterTHeader(column) {
      var obj = {}
      let arr = []
      column.filter.map(i=>{
        obj[i] = true
      })
      this.arr1.map(i=>{
        if(obj[i.prop]) {
          arr.push(i)
        }
      })
      // console.log(arr)
      this.tableHeaders = arr
    },
    onSortChange({ column, prop, order }) {
      this.condition.orderBy = column.columnKey
      if (column.order == 'ascending') {
        this.condition.sequence = 'asc'
      } else if (column.order == 'descending') {
        this.condition.sequence = 'desc'
      }
      this.getList()
    },
    filterHandler() {
      return true
    },
    handleChangePage(page) {
      this.condition.page = page
      this.getList()
    },
    handlePageChange(active) {
      this.condition.size = active
      this.getList()
    },
    uploadSuccess(response, file, fileList) {
      // console.log(response, file, fileList)
      let files = new File([JSON.stringify(file)],{type:'application/json'})
      let fd = new FormData
      fd.append('file', files)
      this.$http.post('/import/uploadLmdOrder/TWMSORDER',fd).then(res => {
        if (res.data.code === '200') {
          this.show = false
        }
      })
      this.isDefault = false
      this.uLoading = false
      this.uSuccess = true
      this.uFail = false
    },
    uploadFail() {
      this.isDefault = false
      this.uLoading = false
      this.uSuccess = true
      this.uFail = false
    },
    uploadPro() {
      this.isDefault = false
      this.uLoading = true
      this.uSuccess = false
      this.uFail = false
    },
    exportHandle() {
      // if export success
      this.show1 = false
      this.export1 = false
      this.export2= true
    },
    handleSelectionChange(val) {
      if (val.length > 0) {
        this.splitBtn = false
      } else {
        this.splitBtn = true
      }
    },
    handleSelection(val) {
      this.listResult = val
      if (val.length > 0) {
        this.isShowBtn = false
      } else {
        this.isShowBtn = true
      }
    },
    handleCommand(d) {
      if (d.orderNumber !== undefined) {
        this.orderNumber = d.orderNumber
      } if(d.orderNo !== undefined) {
        this.orderNo = d.orderNo
      } else if (d === 'track') {
        this.$router.push({ name: 'track' })
      }
      this.salesId = d.id
      if (typeof d == 'number') {
        this.printLabel(d)
      }
    },
    printLabel(d) {
      let arr = []
      arr.push(d)
      this.$http.post('/api/foms/labelUrlReport',arr).then(res=>{
        if (res.data.code == '200'){
          this.$router.push({ name: 'upload-order' })
        }
      })
    },
    onSearch() {
      const userData = JSON.parse(localStorage.getItem('userInfo'))
      this.condition.clientCode = userData.organizationName
      console.log(this.time)
    },
    onReset() {
      this.condition.orderNumber = null
      this.condition.clientCode = null
      this.condition.latestStatus = null
      this.status = null
      this.time = ''
      this.condition.dateRangeFrom = ''
      this.condition.dateRangeTo = ''
    },
    handleCheckAllChange(val) {
      // this.checkedFilter = val ? this.filters : []
      const arr = []
      this.filters.map(i=>{
        arr.push(i.value)
      })
      this.checkedFilter = val ? arr : []
      this.isIndeterminate = false
    },
    handleChecked(value) {
      console.log('2', value)
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.filters.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.filters.length;
    },
    listenCreateModel(val) {
      this.show7 = val
      this.getList()
    },
    listenDelModel(val) {
      this.show3 = val
      this.getList()
    },
    listenDeliveryModel(val) {
      this.show5 = val
      this.getList()
    },
    listenLineModel(val) {
      this.show6 = val
      this.getList()
    },
    onOrderDetail(id) {
      this.$refs['modal-orderDetail'].show()
      this.salesId = id
    },
    onDelOrder() {
      this.$http.delete(`/api/foms/order/delete?orderNo=${this.orderNo}`).then(res=>{
        if (res.data.code == '200'){
          this.show3 = false
          this.show8 = false
          this.getList()
        }
      })
    },
    downTem: debounce(function() {
      let url = axios.defaults.baseURL+`/import/template/TWMSORDER`
      window.open(url, "_self")
    }, 1000),
    getProcessStatus() {
      this.$http.post('/import/getProcessStatus').then(res => {
        this.items = res.data.data
        this.show2 = false
      })
    },
    onUpload() {
      this.$refs.upload.submit()
    },
  },
}
</script>
<style scoped>
.ll-readonlyInput{
  background-color: #fff;
}
.ll-spaceEvenly{
  justify-content: space-evenly;
}
.ll-icon{
  width: 1.5em;
  height: 1.5em;
  fill: currentColor;
  overflow: hidden;
}
.ll-icon-small {
  width: 15px;
  height: 15px;
}
.ll-normal{
  width: 2em;
  height: 2em;
  fill: #28c76f;
}
.ll-normal1{
  width: 2em;
  height: 2em;
  fill: currentColor;
}
.ll-issue{
  width: 2em;
  height: 2em;
  fill: #ea5455;
}
.ll-badge{
  padding: 1rem;
}
.ll-issueBox{
  color: #ea5455;
}
.ll-flexEnd{
  justify-content: flex-end;
}
.ll-ulBox ul{
  list-style: none;
  padding-inline-start: 20px;
}
.ll-dropdown{
  width: 150px;
}
.ll-delicon{
  width: 5em;
  height: 5em;
  fill: #ea5455;
}
.ll-uploadIcon{
  width: 5em;
  height: 5em;
}
.ll-success{
  fill: #28c76f;
}
.ll-fail{
  fill: #ea5455;
}
.ll-uploadText{
  font-weight: bold;
  font-size: 24px;
}
.ll-box{
  justify-content: space-between;
}
.ll-count{
  padding: 5px 10px;
  border-radius: 50%;
  background-color: #fff;
}
.ll-datePicker{
  width: 100%;
  height: 34px;
}
.ll-filterBox{
  justify-content: space-between;
}
</style>
<style>
.ll-upload .el-upload-dragger{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.ll-upload .el-upload{
  width: 100%;
}
.table thead th, .table tfoot th {
  text-transform: capitalize;
}
/* #161d31 !important */
[dir] .table.b-table > tbody .b-table-row-selected.table-active td{
  background-color: unset;
  background: unset;
}
[dir] .table.b-table.b-table-selectable:not(.b-table-selectable-no-click) > tbody > tr{
  background-color: unset;
  background: unset;
}
</style>