<template>
  <section>
    <div class="ll-headerBox mb-1">
      <span class="mr-1" style="font-size: 14px;">Sales Order / {{detailsInfo.orderNo}}</span>
      
      <b-badge
        pill
        class="ll-badge mr-1 hide"
        variant="light-secondary"
      >
        create
      </b-badge>
      
      <el-tooltip placement="top" effect="light">
        <div slot="content">
          <!--
          <div class="ll-issueBox">
            <feather-icon
              icon="AlertCircleIcon"
              size="24"
            />
            <span>Please finish the shipping address</span>
          </div>
          !-->
        </div>
        <!--
        <svg aria-hidden="true"
          class="ll-issue"
        >
          <use xlink:href="#icon-image-_2_"></use>
        </svg>
        !-->
      </el-tooltip>
      <span class="ll-floatRight">
        <b-button variant="flat-secondary" @click="printLabel">
          <svg aria-hidden="true" class="ll-icon">
            <use xlink:href="#icon-Waybill"></use>
          </svg>
          <span class="align-middle ml-50 mr-1">Print Label</span>
        </b-button>
        <!-- <feather-icon icon="FileTextIcon" />
        <span class="align-middle ml-50 mr-1">Print Invoice</span> -->
        <b-button
          variant="outline-primary"
          class="btn"
          v-b-modal.modal-del1
        >
          {{ $t('Delete') }} {{ $t('Order') }}
        </b-button>
      </span>
    </div>

    <!-- <el-steps :active="1" class="mb-1" finish-status="finish">
      <el-step title="Order Created" description="2021-11-08 12:00:00">
        <template #icon>
          <svg aria-hidden="true" class="ll-icon">
            <use xlink:href="#icon-image-_2_"></use>
          </svg>
        </template>
      </el-step>
      <el-step title="Processing" description="2021-11-08 12:00:00">
        <template #icon>
          <svg aria-hidden="true" class="ll-icon">
            <use xlink:href="#icon-loading_filled"></use>
          </svg>
        </template>
      </el-step>
      <el-step title="Handover">
        <template #icon>
          <svg aria-hidden="true" class="ll-icon">
            <use xlink:href="#icon-product-delivery"></use>
          </svg>
        </template>
      </el-step>
      <el-step title="Delivering">
        <template #icon>
          <svg aria-hidden="true" class="ll-icon">
            <use xlink:href="#icon-peisongguanli"></use>
          </svg>
        </template>
      </el-step>
      <el-step title="Delivered">
        <template #icon>
          <feather-icon
            icon="CheckIcon"
            size="26"
          />
        </template>
      </el-step>
    </el-steps> -->
    <b-row>
      <b-col lg="8">
        <b-card class="mb-1 ll-cardHei">
          <b-card-header class="ll-cardHeader mb-1">
            <b-card-title>{{ $t('Order') }} {{ $t('Details') }}</b-card-title>
          </b-card-header>
          <b-card-body class="ll-cardBody">
            <b-row>
              <b-col>
                <label class="font-weight-bold">{{$t("Customer")}}</label>
                <p>{{detailsInfo.ownerCode}}</p>
              </b-col>
              <b-col>
                <label class="font-weight-bold">{{$t("Warehouse")}}</label>
                <p>{{detailsInfo.warehouseCode}}</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <label class="font-weight-bold">{{$t("Create Date")}}</label>
                <p>{{detailsInfo.createdAt}}</p>
              </b-col>
              <b-col>
                <label class="font-weight-bold">{{$t("Order Type")}}</label>
                <p v-if="(detailsInfo.orderType === 'SOD')">Outbound delivery</p>
                <p v-else-if="(detailsInfo.orderType === 'SODR')">Outbound delivery reserved</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <label class="font-weight-bold">{{$t("Shipment term")}}</label>
                <p>{{detailsInfo.shipmentTerm}}</p>
              </b-col>
              <b-col>
                <label class="font-weight-bold">{{$t("Logistics Provider")}}</label>
                <p>{{detailsInfo.logisticsCode}}</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <label class="font-weight-bold">{{$t("Payment method")}}</label>
                <p v-if="(detailsInfo.paymentMethod === 'PP')">Pre-paid</p>
                <p v-if="(detailsInfo.paymentMethod === 'COD')">Cash on Delivery</p>
              </b-col>
              <b-col>
                <label class="font-weight-bold">{{$t("Total")}} {{$t("Value")}}</label>
                <p
                  v-if="(detailsInfo.goodsValueCurrency==='JPY' || detailsInfo.goodsValueCurrency==='VND' || detailsInfo.goodsValueCurrency==='IDR' || detailsInfo.goodsValueCurrency==='KRW')">
                  {{detailsInfo.goodsValueCurrency}}
                  {{ detailsInfo.totalGoodsValue ? detailsInfo.totalGoodsValue.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : detailsInfo.totalGoodsValue}}</p>
                <p v-else>{{detailsInfo.goodsValueCurrency}}
                  {{ detailsInfo.totalGoodsValue ? detailsInfo.totalGoodsValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : detailsInfo.totalGoodsValue}}</p>
              </b-col>
            </b-row>
            <!--
            <b-row>
              <b-col>
                <label class="font-weight-bold">{{$t("Currency")}}</label>
                <p>{{detailsInfo.goodsValueCurrency}}</p>
              </b-col>
            </b-row> -->
          </b-card-body>
        </b-card>
      </b-col>
      <b-col lg="4" class="pl-0 pr-0">
        <b-card class="ll-cardHei">
          <b-card-header class="ll-cardHeader mb-1">
            <b-card-title>{{ $t('Delivery Information') }} </b-card-title>
            <b-button
              variant="info"
              @click="onDetailEdit"
              v-if="!isEdit"
            >
              <span>{{ $t('Edit') }}</span>
            </b-button>
            <b-button
              variant="info"
              @click="onDetailSave"
              v-if="isEdit"
            >
              <span>{{ $t('Save') }}</span>
            </b-button>
          </b-card-header>
          <b-card-body class="ll-cardBody">
            <p class="mb-0 font-weight-bold">{{ $t('Ship from') }}:</p>
            <div class="mb-1" v-if="!isEdit">
              <div>{{detailsInfo.senderName}}</div>
              <div>{{detailsInfo.senderAddress}}</div>
              <div>{{detailsInfo.senderDistict}}</div>
              <div>{{detailsInfo.senderCity}}</div>
              <div>{{detailsInfo.senderPhone}}</div>
              <!-- <span>Kerry</span><br>
              <span>55 Wing Kei Road,</span><br>
              <span>Kwai Chung,</span><br>
              <span>Hong Kong</span><br>
              <span>+852 22330011</span> -->
            </div>
            <div class="mb-1" v-if="isEdit">
              <b-form-input v-model="detailsInfo.senderName" class="mb-1" placeholder="Sender Name"/>
              <b-form-input v-model="detailsInfo.senderAddress" class="mb-1" placeholder="Sender Address"/>
              <b-form-input v-model="detailsInfo.senderDistict" class="mb-1" placeholder="Sender Distict"/>
              <b-form-input v-model="detailsInfo.senderCity" class="mb-1" placeholder="Sender City"/>
              <b-form-input v-model="detailsInfo.senderPhone" class="mb-1" placeholder="Sender Phone"/>
            </div>
            <p class="mb-0 font-weight-bold">{{ $t('Ship to') }}:</p>
            <div class="mb-1" v-if="!isEdit">
              <div>{{detailsInfo.cneeName}}</div>
              <div>{{detailsInfo.cneeAddress}}</div>
              <div>{{detailsInfo.cneeDistrict}}</div>
              <div>{{detailsInfo.cneeCity}}</div>
              <div>{{detailsInfo.cneePhone}}</div>
            </div>
            <div class="mb-1" v-if="isEdit">
              <b-form-input v-model="detailsInfo.cneeName" class="mb-1" placeholder="Consignee Name"/>
              <b-form-input v-model="detailsInfo.cneeAddress" class="mb-1" placeholder="Consignee Address"/>
              <b-form-input v-model="detailsInfo.cneeDistrict" class="mb-1" placeholder="Consignee District"/>
              <b-form-input v-model="detailsInfo.cneeCity" class="mb-1" placeholder="Consignee City"/>
              <b-form-input v-model="detailsInfo.cneePhone" class="mb-1" placeholder="Consignee Phone"/>
            </div>
            <div class="d-flex justify-content-end">
              <b-button
                variant="secondary"
                @click="onDetailCancel"
                v-if="isEdit"
              >
                <span>{{ $t('Cancel') }}</span>
              </b-button>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-card>
      <b-card-header class="ll-cardHeader mb-1 ll-cardHead">
        <b-card-title>{{ $t('Item') }} </b-card-title>
        <b-button
          variant="outline-primary"
          class="ml-1"
          v-b-modal.modal-addLineItem
        >
          {{ $t('Add') }} {{ $t('Item') }} +
        </b-button>
        <!-- <b-button
          variant="outline-primary"
          class="ml-1"
          v-b-modal.modal-splitOrder1
        >
          {{ $t('Split') }} {{ $t('Order') }}
        </b-button> -->
      </b-card-header>
      <b-card-body class="ll-cardBody">
        <vxe-table
          border
          show-overflow
          keep-source
          ref="xTable"
          max-height="400"
          :data="detailsInfo.orderWmsDetailList"
          :edit-config="{ trigger: 'manual', mode: 'row', showStatus: true }"
          :empty-text="$t('No matching records found')"
        >
          <vxe-table-column field="itemName"
            :title="$t('Item')"
            :edit-render="{name: 'input'}"
          >
          </vxe-table-column>
          <vxe-table-column field="skuCode"
            title="SKU"
            :edit-render="{name: 'input'}"
          >
          </vxe-table-column>
          <vxe-table-column field="qty" title="QTY"
            :edit-render="{name: 'input', attrs: {type: 'number'}}"
          >
          </vxe-table-column>
          <vxe-table-column
            :title="$t('Price')" field="unitPrice"
            :edit-render="{name: 'input', attrs: {type: 'number'}}"
          >
            <template v-slot:header>
              <span style="float:right;">Price</span>
            </template>
            <template #default="{ row }">
              <span style="float: right;" v-if="(row.currency==='JPY' || row.currency==='VND' ||  row.currency ==='IDR' || row.currency ==='KRW')">{{ row.currency }} {{ row.unitPrice.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</span>
              <span style="float: right;" v-else>{{ row.currency }} {{ row.unitPrice.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</span>
            </template>
          </vxe-table-column>
          <!--
          <vxe-table-column field="currency"
            :title="$t('Currency')"
            :edit-render="{name: 'input'}"
          >
          </vxe-table-column>-->
          <vxe-table-column field="origin"
            :title="$t('Origin')"
            :edit-render="{name: 'input'}"
          >
          </vxe-table-column>
          <vxe-table-column field="userDefinedField"
            title="UDF"
            :edit-render="{name: 'input'}"
          >
          </vxe-table-column>
          <vxe-table-column field="batchNumber"
            :title="$t('Batch No')+'.'" width="120"
            :edit-render="{name: 'input'}"
          >
          </vxe-table-column>
          <vxe-table-column field="expiryDay"
            :title="$t('Expiry Day')" width="120"
            :edit-render="{name: 'input'}"
          >
          </vxe-table-column>
          <vxe-table-column field="condition"
            :title="$t('Condition')" width="120"
            :edit-render="{name: 'input'}"
          >
          </vxe-table-column>

          <vxe-table-column :title="$t('Action')" show-overflow>
            <template #default="{ row }">
              <template v-if="$refs.xTable.isActiveByRow(row)">
                <b-button
                  variant="primary"
                  class="mr-1"
                  @click="saveRowEvent(row)"
                >
                  <span>{{ $t('Update') }}</span>
                </b-button>
                <b-button
                  variant="secondary"
                  @click="cancelRowEvent(row)"
                >
                  <span>{{ $t('Cancel') }}</span>
                </b-button>
              </template>
              <template v-else>
                <vxe-button type="text" @click="editRowEvent(row)">
                  <feather-icon icon="EditIcon" />
                </vxe-button>
                <vxe-button type="text" icon="" @click="removeEvent(row)">
                  <feather-icon icon="Trash2Icon" />
                </vxe-button>
              </template>
            </template>
            <!-- <template #default="{ row }" >
              <vxe-button type="text" @click="editRowEvent(row)">
                <feather-icon icon="EditIcon" />
              </vxe-button>
              <vxe-button type="text" icon="" @click="removeEvent(row)">
                <feather-icon icon="Trash2Icon" />
              </vxe-button>
            </template> -->
          </vxe-table-column>
        </vxe-table>
      </b-card-body>
    </b-card>
    <!-- Adding line item -->
    <b-modal
      id="modal-addLineItem"
      cancel-variant="outline-secondary"
      ok-title=""
      centered
      title="Adding items"
      size="xl"
      v-model="isShow"
    >
      <b-input-group class="input-group-merge mb-1">
        <b-form-input
          placeholder="Search...(Item name or SKU)"
          type="search"
        />
        <b-input-group-append is-text>
          <feather-icon
            icon="SearchIcon"
            class="cursor-pointer"
          />
        </b-input-group-append>
      </b-input-group>
      <vxe-table
        border
        show-overflow
        keep-source
        ref="xTable1"
        max-height="400"
        :data="addItem"
        :edit-config="{ trigger: 'click', mode: 'row', icon:'fa fa-pencil', showStatus: true }"
        :empty-text="$t('No matching records found')"
        @checkbox-change="getSelectionEvent"
        @checkbox-all="getSelectionEvent"
      >
        <vxe-column type="checkbox" width="60"></vxe-column>
        <vxe-table-column field="itemDesc" :title="$t('Item')" >
        </vxe-table-column>
        <vxe-table-column field="itemCode" title="SKU" >
        </vxe-table-column>
        <vxe-table-column field="availableQty" :title="$t('Inventory')" >
        </vxe-table-column>
        <vxe-table-column field="unitPrice" :title="$t('Price')" >
        </vxe-table-column>
        <vxe-table-column field="currency" :title="$t('Currency')" >
        </vxe-table-column>
        <vxe-table-column field="coo" :title="$t('Origin')" >
        </vxe-table-column>
        <vxe-table-column field="userDefinedField" title="UDF" >
        </vxe-table-column>
        <vxe-table-column field="batchNumber" :title="$t('Batch No')+'.'" >
        </vxe-table-column>
        <vxe-table-column field="expiryDay" :title="$t('Expiry Day')" >
        </vxe-table-column>
        <vxe-table-column field="condition" :title="$t('Condition')" >
        </vxe-table-column>
        <vxe-table-column field="qty" title="QTY" >
          <template #default="{ row }">
            <b-form-input v-model="row.qty" :disabled="row.isDisable" type="number"/>
          </template>
        </vxe-table-column>
      </vxe-table>
      <p class="ll-textAlignRight mt-1 mr-1">
        <span class="font-weight-bolder">Total: {{addItem.length}}</span>
      </p>
      <template #modal-footer>
        <b-button
          variant="outline-secondary"
          class="btn"
          @click="isShow = false"
        >
          {{ $t('Cancel') }}
        </b-button>
        <b-button
          variant="primary"
          class="btn"
          @click="addItems"
        >
          {{ $t('Add') }}
        </b-button>
      </template>
    </b-modal>
    <!-- Split Order -->
    <b-modal
      id="modal-splitOrder1"
      cancel-variant="outline-secondary"
      ok-title=""
      centered
      :title="'Split Order / '+ orderNumber"
      v-model="isShow1"
      size="lg"
    >
      <el-table
        :data="splitOrderList1"
        @selection-change="handleSelectionChange1"
        size="small"
        stripe
        :empty-text="$t('No matching records found')"
      >
        <el-table-column
          type="selection"
          width="50">
        </el-table-column>
        <template v-for="(item, index) in orderHeaders1">
          <el-table-column
            :key="index"
            :label="item.label"
            :prop="item.prop"
            :width="item.width"
          >
          </el-table-column>
        </template>
      </el-table>
      <template #modal-footer>
        <b-button
          variant="outline-secondary"
          class="btn"
          @click="isShow1 = false"
        >
          {{ $t('Cancel') }}
        </b-button>
        <b-button
          variant="primary"
          class="btn"
          :disabled="splitBtn1"
          :style="{'cursor': splitBtn1 ? 'no-drop' : 'pointer'}"
          @click="isShow1 = false"
        >
          {{ $t('Split') }}
        </b-button>
      </template>
    </b-modal>
    <!-- del modal -->
    <b-modal
      id="modal-del1"
      cancel-variant="outline-secondary"
      ok-title=""
      centered
      title=""
      v-model="showdel"
    >
      <div class="text-center">
        <svg aria-hidden="true" class="ll-delicon">
          <use xlink:href="#icon-delete"></use>
        </svg><p/>
        <p style="font-size:18px;"><b>Delete Order</b></p>
        <p>You are deleting an orders<br>
          <b>This action cannot be undone</b>
        </p>
      </div>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-center">
          <b-button
            variant="outline-secondary"
            class="btn mr-2"
            @click="showdel = false"
          >
            {{ $t('Cancel') }}
          </b-button>
          <b-button
            variant="primary"
            class="btn"
            @click="onDelOrder1"
          >
            {{ $t('Confirm') }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BFormGroup, BFormInput,
  BButton,  BInputGroup, BModal, VBModal,
  BCardHeader, BCardTitle, BCardBody,
  BInputGroupAppend, BFormCheckbox, BBadge, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroup,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BModal,
    BInputGroupAppend,
    BFormCheckbox,
    BBadge,
    BFormTextarea,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    orderNumber: String,
    salesId: Number,
  },
  data() {
    return {
      item: [],
      isShow: false,
      isShow1: false,
      addItem: [],
      selectRecords: null,
      splitBtn1: true,
      orderHeaders1: [
        { label: this.$t('Item'), prop: "itemName", width: "auto" },
        { label: 'SKU', prop: "skuCode", width: "auto" },
        { label: 'QTY', prop: "qty", width: "auto" },
        { label: this.$t('Currency'), prop: "currency", width: "auto" },
        { label: this.$t('Origin'), prop: "origin", width: "auto" },
        { label: 'UDF', prop: "userDefinedField", width: "auto" },
        { label: this.$t('Batch No')+'.', prop: "batchNumber", width: "100" },
        { label: this.$t('Expiry Day'), prop: "expiryDay", width: "100" },
        { label: this.$t('Condition'), prop: "condition", width: "100" },
      ],
      splitOrderList1: [
        {
          itemName: "Mask",
          skuCode: "HHH-123",
          qty: 5,
          unitPrice: 15,
          currency: "HKD",
          origin: "HK",
          userDefinedField: "",
          batchNumber: "121-24",
          expiryDay: "2021-12-10",
          condition: "GOOD"
        }
      ],
      detailsInfo: {},
      isEdit: false,
      isEditItem: false,
      showdel: false,
      delModel: false,
    }
  },
  mounted(){
    this.pushVal()
    this.getDetails()
  },
  methods: {
    printLabel() {
      let arr = []
      arr.push(this.salesId)
      this.$http.post('/api/foms/labelUrlReport',arr).then(res=>{
        if (res.data.code == '200'){
          this.$router.push({ name: 'upload-order' })
        }
      })
    },
    onDelOrder1() {
      this.$http.delete(`/api/foms/order/delete?orderNo=${this.detailsInfo.orderNo}`).then(res=>{
        if (res.data.code == '200'){
          this.showdel = false
          this.$emit('delModel', this.delModel)
        }
      })
    },
    getSKUItem(d) {
      this.$http.get(`/api/foms/sku/list?clientCode=${d}`).then(res => {
        this.addItem = res.data.data
        this.pushVal()
      })
    },
    getDetails() {
      this.$http.get(`/api/foms/order/detail/${this.salesId}`).then(res=>{
        this.detailsInfo = res.data.data
        this.getSKUItem(res.data.data.ownerCode)
      })
    },
    pushVal() {
      this.addItem.map(i=>{
        i.isDisable = true
      })
    },
    editRowEvent (row) {
      const $table = this.$refs.xTable
      $table.setActiveRow(row)
      this.isEditItem = true
    },
    removeEvent(row) {
      this.box = "";
      this.$bvModal.msgBoxConfirm("Please confirm that you want to delete .", {
        title: "Handle",
        size: "sm",
        okVariant: "primary",
        okTitle: "Yes",
        cancelTitle: "No",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      })
      .then((value) => {
        if (value) {
          this.box = value;
          this.$refs.xTable.remove(row);
        }
      });
    },
    saveRowEvent () {
      const $table = this.$refs.xTable
      $table.clearActived().then(() => {
        setTimeout(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Update Success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        }, 300)
      })
    },
    cancelRowEvent (row) {
      const $table = this.$refs.xTable
      $table.clearActived().then(() => {
        // 还原行数据
        $table.revertData(row)
      })
    },
    getSelectionEvent (row) {
      if (row.records.length > 0) {
        row.records.map(i=>{
          i.isDisable = false
        })
        let arr = []
        arr = this.addItem.filter(x => !row.records.includes(x))
        arr.map(i=>{
          i.isDisable = true
        })
      } else {
        this.addItem.map(i=>{
          i.isDisable = true
        })
      }
      const $table = this.$refs.xTable1
      this.selectRecords = $table.getCheckboxRecords()
    },
    addItems() {
      this.selectRecords.map(i=>{
        if (!i.isDisable) {
          if (i.qty == null || i.qty.trim() == ''){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'QTY can not be empty',
                icon: 'XIcon',
                variant: 'danger',
              },
            })
            this.isShow = true
            return
          }
        }
        this.addItem = this.addItem.filter(x => !this.selectRecords.includes(x))

        const obj = {}
        obj.name = i.itemDesc
        obj.item_code = i.itemCode
        obj.origin_country = i.coo
        obj.qty = i.qty
        obj.unit_price = i.unitPrice
        obj.currency = i.currency
        this.item.push(obj)
        this.isShow = false
        this.$http.post(`/api/foms/order/addItem/${this.salesId}`, this.item).then(res=>{
          if (res.data.code == '200') {
            this.detailsInfo.orderWmsDetailList = this.item
          }
        })
      })
    },
    handleSelectionChange1(val) {
      if (val.length > 0) {
        this.splitBtn1 = false
      } else {
        this.splitBtn1 = true
      }
    },
    onDetailEdit() {
      this.isEdit = true
    },
    onDetailSave() {
      this.isEdit = false
      const obj = {}
      obj.id = this.detailsInfo.id
      obj.shipper = {}
      obj.shipper.name = this.detailsInfo.senderName
      obj.shipper.phone = this.detailsInfo.senderPhone
      obj.shipper.address = this.detailsInfo.senderAddress
      obj.shipper.city = this.detailsInfo.senderCity
      obj.shipper.country = this.detailsInfo.senderCountryCode
      obj.shipper.state = this.detailsInfo.senderState
      obj.consignee = {}
      obj.consignee.name = this.detailsInfo.cneeName
      obj.consignee.phone = this.detailsInfo.cneePhone
      obj.consignee.address = this.detailsInfo.cneeAddress
      obj.consignee.city = this.detailsInfo.cneeCity
      obj.consignee.country = this.detailsInfo.cneeCountryCode
      obj.consignee.cneeState = this.detailsInfo.cneeState
      arr.push(obj)
      this.$http.put('/api/foms/order/editDeliveryInfo', arr).then(res=>{
        if (res.data.code == '200') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Save Success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        }
      })
    },
    onDetailCancel() {
      this.isEdit = false
    },
  },
}
</script>
<style scoped>
.ll-normal{
  width: 1.5em;
  height: 1.5em;
  fill: #28c76f;
}
.ll-issue{
  width: 1.5em;
  height: 1.5em;
  fill: #ea5455;
}
.ll-badge{
  padding: 1rem;
}
.ll-issueBox{
  color: #ea5455;
}
.ll-icon{
  width: 1.5em;
  height: 1.5em;
  fill: currentColor;
  overflow: hidden;
}
.ll-floatRight{
  float: right;
}
.ll-cardBody, .ll-cardHeader{
  padding: 0;
}
.ll-cardHei {
  height: 315px;
}
.ll-cardHei>.card-body{
  overflow-y: auto;
}
.ll-cardHead{
  justify-content: flex-start;
}
.ll-textAlignRight{
  text-align: right;
}
</style>
<style>
.el-step__icon{
  width: 50px;
  height: 50px;
  font-size: 20px;
  top: -10px;
}
[dir] .el-step__head.is-finish{
  border-color: #ed6d01;
}
.el-step__head.is-finish {
  color: #ed6d01;
}
.el-step__title.is-finish {
  color: #ed6d01;
}
.el-step__description.is-finish {
  color: #ed6d01;
}
#item-price .vxe-cell {
  float: right;
}
.vxe-cell--edit-icon {
  display: none;
}
.row {
  margin-bottom: 10px;
}
.row .col label {
  font-weight: 700 !important;
}
p.mb-0.font-weight-bold {
  font-weight: 700 !important;
}
.hide {
  visibility: hidden;
}
</style>
