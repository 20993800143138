<template>
  <section class="ll-modelHei" ref="list">
    <validation-observer ref="rules">
    <div class="infinite-list" >
      <div v-for="(item,index) in scrollList" :key="index" class="infinite-list-item">
    <b-row>
      <b-col lg="8">
        <div class="ll-orderBox mb-1">{{item.orderNo}}</div>
      </b-col>
    </b-row>
    <!-- sender -->
    <b-row>
      <b-col lg="4">
        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Sender Name"
            rules="required"
          >
            <label class="ll-boldText">{{ $t("Sender Name") }}</label>
            <b-form-input v-model="item.senderName"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col lg="4">
        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Sender Phone"
            rules="required"
          >
            <label class="ll-boldText">{{ $t("Sender Phone") }}</label>
            <b-form-input v-model="item.senderPhone"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12">
        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Sender Address"
            rules="required"
          >
            <label class="ll-boldText">{{ $t("Sender Address") }}</label>
            <b-form-input v-model="item.senderAddress"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="4">
        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Sender City"
            rules="required"
          >
            <label class="ll-boldText">{{ $t("Sender City") }}</label>
            <b-form-input v-model="item.senderCity"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col lg="4">
        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Sender State"
            rules="required"
          >
            <label class="ll-boldText">{{ $t("Sender State") }}</label>
            <b-form-input v-model="item.senderState"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col lg="4">
        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Sender Country"
            rules="required"
          >
            <label class="ll-boldText">{{ $t("Sender Country") }}</label>
            <v-select
              v-model="item.senderCountryCode"
              :options="countryList"
              label="codeDescription"
              @input="selCountry1(item)"
            >
            </v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <!-- Consignee -->
    <b-row>
      <b-col lg="4">
        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Consignee Name"
            rules="required"
          >
            <label class="ll-boldText">{{ $t("Consignee Name") }}</label>
            <b-form-input v-model="item.cneeName"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col lg="4">
        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Consignee Phone"
            rules="required"
          >
            <label class="ll-boldText">{{ $t("Consignee Phone") }}</label>
            <b-form-input v-model="item.cneePhone"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12">
        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Consignee Address"
            rules="required"
          >
            <label class="ll-boldText">{{ $t("Consignee Address") }}</label>
            <b-form-input v-model="item.cneeAddress"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="4">
        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Consignee City"
            rules="required"
          >
            <label class="ll-boldText">{{ $t("Consignee City") }}</label>
            <b-form-input v-model="item.cneeCity"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col lg="4">
        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Consignee State"
            rules="required"
          >
            <label class="ll-boldText">{{ $t("Consignee State") }}</label>
            <b-form-input v-model="item.cneeState"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col lg="4">
        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Consignee Country"
            rules="required"
          >
            <label class="ll-boldText">{{ $t("Consignee Country") }}</label>
            <v-select
              v-model="item.cneeCountryCode"
              :options="countryList"
              label="codeDescription"
              @input="selCountry2"
            >
            </v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <hr>
    </div>
    <div v-if="isbottom" class="ll-loadBox font-weight-bold">
      <span>Loading More...</span>
    </div>
    <div v-if="!isbottom" class="ll-loadBox font-weight-bold">
      <span>no more data</span>
    </div>
    </div>
    <div class="d-flex justify-content-end">
      <b-button
        variant="outline-secondary"
        class="btn mr-1"
        @click="$emit('deliveryModel', isDeliveryModel)"
      >
        {{ $t('Cancel') }}
      </b-button>
      <b-button
        variant="primary"
        class="btn"
        @click.prevent="validationForm"
      >
        {{ $t('Save all') }}
      </b-button>
    </div>
    </validation-observer>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BFormGroup, BFormInput,
  BButton,  BInputGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, emails } from '@validations'
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroup,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
  },
  props: {
    listResult: Array,
  },
  data() {
    return {
      required,
      emails,
      scrollList: [],
      shipper: {
        name: null,
        phone: null,
        country: null,
        state: null,
        city: null,
        address: null
      },
      consignee: {
        name: null,
        phone: null,
        country: null,
        state: null,
        city: null,
        address: null
      },
      isbottom: false,
      countryList: [],
      arrP: [],
      isDeliveryModel: false
    }
  },
  created() {},
  mounted(){
    this.load()
    this.queryCountry()
    this.$refs.list.addEventListener('scroll',this.handleScroll,true);
  },
  methods: {
    load() {
      if (this.listResult.length>1) {
        this.isbottom = true
      } else {
        this.isbottom = false
      }
      // this.scrollList.push(this.listResult[0])
      this.arrP.push(this.listResult[0].id)
      this.$http.post('/api/foms/order/detail',this.arrP).then(res=>{
        this.scrollList = res.data.data
      })
    },
    handleScroll(e) {
      let scrollTop = e.target.scrollTop //距离顶部的距离
      let windowHeight = e.target.clientHeight //可视区的高度
      let scrollHeight = e.target.scrollHeight //滚动条的总高度
      if(scrollTop + windowHeight >= scrollHeight && this.isbottom){
        let arr = []
        for (let i=1;i<this.listResult.length;i++){
          // this.scrollList.push(this.listResult[i])
          arr.push(this.listResult[i].id)
        }
        let arr2 = this.arrP.concat(arr)
        this.$http.post('/api/foms/order/detail',arr2).then(res=>{
          this.scrollList = res.data.data
        })
      }
      if (this.scrollList.length == this.listResult.length) {
        this.isbottom = false
      }
    },
    queryCountry() {
      this.$http.get('/masterData/findAllByDataType', { params: { data_type: 'country'}}).then(res => {
        this.countryList = res.data.data
      })
    },
    selCountry1(d) {
      console.log(d)
    },
    selCountry2(d) {
    },
    validationForm() {
      this.$refs.rules.validate().then(success => {
        if (success) {
          this.saveAll()
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Please check the data',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          return false
        }
        return true
      })
    },
    saveAll() {
      let arr = []
      this.scrollList.map(i=>{
        let obj = {}
        obj.id = i.id
        obj.shipper = {}
        obj.shipper.name = i.senderName
        obj.shipper.phone = i.senderPhone
        obj.shipper.address = i.senderAddress
        obj.shipper.city = i.senderCity
        obj.shipper.country = i.senderCountryCode
        obj.shipper.state = i.senderState
        obj.consignee = {}
        obj.consignee.name = i.cneeName
        obj.consignee.phone = i.cneePhone
        obj.consignee.address = i.cneeAddress
        obj.consignee.city = i.cneeCity
        obj.consignee.country = i.cneeCountryCode
        obj.consignee.cneeState = i.cneeState
        arr.push(obj)
      })
      // console.log(arr)
      this.$http.put('/api/foms/order/editDeliveryInfo', arr).then(res=>{
        if (res.data.code == '200') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Save Success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$emit('deliveryModel', this.isDeliveryModel)
        }
      })
    },
  },
}
</script>
<style scoped>
.ll-orderBox{
  padding: 0.5rem;
  border: 1px solid;
  background: #eee;
  font-size: 24px;
  font-weight: bold;
}
.ll-loadBox{
  text-align: center;
  font-size: 16px;
}
.ll-modelHei{
  height: 495px;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
