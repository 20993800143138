<template>
  <section>
    <swiper class="swiper-navigations" :options="swiperOptions">
      <swiper-slide
        v-for="(item, index) in swiperData" :key="index"
        class="rounded swiper-shadow"
      >
        <div class="ll-orderBox mb-2">{{ item.orderNo }}</div>
        <vxe-table
          border
          show-overflow
          keep-source
          ref="xTable"
          max-height="400"
          :data="item.itemList"
          :edit-config="{ trigger: 'manual', mode: 'row', showStatus: true }"
          :empty-text="$t('No matching records found')"
          class="ll-vexTable"
        >
          <vxe-table-column field="itemName" :title="$t('Item')" >
            <template #default="{ row }">
              <b-form-input v-model="row.itemName"/>
            </template>
          </vxe-table-column>
          <vxe-table-column field="itemCode" title="SKU" >
            <template #default="{ row }">
              <b-form-input v-model="row.itemCode"/>
            </template>
          </vxe-table-column>
          <vxe-table-column field="qty" title="QTY" >
            <template #default="{ row }">
              <b-form-input v-model="row.qty" type="number"/>
            </template>
          </vxe-table-column>
          <vxe-table-column field="unitPrice" :title="$t('Price')" >
            <template #default="{ row }">
              <b-form-input v-model="row.unitPrice" type="number"/>
            </template>
          </vxe-table-column>
          <vxe-table-column field="currency" :title="$t('Currency')" >
            <template #default="{ row }">
              <b-form-input v-model="row.currency"/>
            </template>
          </vxe-table-column>
          <vxe-table-column field="coo" :title="$t('Origin')" >
            <template #default="{ row }">
              <b-form-input v-model="row.coo"/>
            </template>
          </vxe-table-column>
          <vxe-table-column field="udf" title="UDF" >
            <template #default="{ row }">
              <b-form-input v-model="row.udf"/>
            </template>
          </vxe-table-column>
          <vxe-table-column field="batchCode" :title="$t('Batch No')+'.'" >
            <template #default="{ row }">
              <b-form-input v-model="row.batchCode"/>
            </template>
          </vxe-table-column>
          <vxe-table-column field="expiryDay" :title="$t('Expiry Day')" >
            <template #default="{ row }">
              <b-form-input v-model="row.expiryDay"/>
            </template>
          </vxe-table-column>
          <vxe-table-column field="condition" :title="$t('Condition')" >
            <template #default="{ row }">
              <b-form-input v-model="row.condition"/>
            </template>
          </vxe-table-column>
          <!-- <vxe-table-column :title="$t('Action')"  show-overflow>
            <template #default="{ row }" >
              <vxe-button @click="editRowEvent(row)">编辑</vxe-button>
              <vxe-button type="text" icon="" @click="removeEvent(row,index)">
                <feather-icon icon="Trash2Icon" />
              </vxe-button>
            </template>
          </vxe-table-column> -->
        </vxe-table>
      </swiper-slide>

      <!-- Add Arrows -->
      <div slot="button-next" class="swiper-button-next" />
      <div slot="button-prev" class="swiper-button-prev" />
    </swiper>
    <!-- <div class="ll-subBtn mt-1">
      <b-button variant="primary" @click="OnSaveNext"> Save and next </b-button>
    </div> -->
    <div class="d-flex justify-content-end mt-1">
      <b-button
        variant="primary"
        class="btn"
        @click="done"
      >
        {{ $t('Update') }}
      </b-button>
    </div>
  </section>
</template>

<script>
import { BButton, BFormInput } from "bootstrap-vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    Swiper,
    SwiperSlide,
    BButton,
    BFormInput,
    ToastificationContent,
  },
  directives: {},
  props: {
    listResult: Array,
  },
  data() {
    return {
      swiperData: [],
      swiperOptions: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      isSave: true,
      isLineModel: false,
      isUpdate: false,
      arr: [],
    }
  },
  watch: {
  },
  mounted() {
    this.getInfo()
  },
  methods: {
    getInfo() {
      let arr = []
      this.listResult.map(i=>{
        arr.push(i.id)
      })
      this.$http.post('/api/foms/order/item',arr).then(res=>{
        if (res.data.code == '200') {
          this.swiperData = res.data.data
          this.swiperData.map(item=>{
            item.itemList.map(i=>{
              let obj = {}
              obj.id = i.id
              obj.name = i.itemName
              obj.item_code = i.itemCode
              obj.qty = i.qty
              obj.unit_price = i.unitPrice
              obj.currency = i.currency
              obj.origin_country = i.coo
              this.arr.push(obj)
            })
          })
          // console.log(this.arr)
        }
      })
    },
    editRowEvent(row) {
      const $table = this.$refs.xTable
      console.log( $table)
      // $table.setActiveRow(row)
    },
    removeEvent(row,index) {
      this.box = "";
      this.$bvModal.msgBoxConfirm("Please confirm that you want to delete .", {
        title: "Handle",
        size: "sm",
        okVariant: "primary",
        okTitle: "Yes",
        cancelTitle: "No",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      })
      .then((value) => {
        if (value) {
          this.box = value;
          const $table = this.$refs.xTable
          $table[index].remove(row)
          // this.$refs.xTable.remove(row);
          this.swiperData[index].itemList.splice(index,1)
        }
      });
    },
    OnSaveNext(row,index) {
      this.isSave = false
      const $table = this.$refs.xTable
      console.log(index)
      $table[index].clearActived().then(() => {
        console.log('baocun')
      })
    },
    done() {
      let arr = []
      this.swiperData.map(item=>{
        item.itemList.map(i=>{
          let obj = {}
          obj.id = i.id
          obj.name = i.itemName
          obj.item_code = i.itemCode
          obj.qty = Number(i.qty)
          obj.unit_price = parseFloat(i.unitPrice)
          obj.currency = i.currency
          obj.origin_country = i.coo
          arr.push(obj)
        })
      })
      if (JSON.stringify(arr) != JSON.stringify(this.arr)) {
        // this.isUpdate = true
        this.$http.put('/api/foms/order/editItem',arr).then(res=>{
          if (res.data.code == '200'){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Update Success',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$emit('lineModel', this.isLineModel)
          }
        })
      } else {
        // this.isUpdate = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Data not modified',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      }
    }
  },
};
</script>
<style scoped>
.ll-vexTable{
  min-height: 350px;
}
.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 2rem;
}
.ll-subBtn {
  text-align: right;
  height: 50px;
}
.ll-orderBox {
  padding: 0.5rem;
  border: 1px solid;
  background: #eee;
  font-size: 24px;
  font-weight: bold;
}
</style>
<style>
.swiper-button-prev:after, .swiper-button-next:after {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #ff9900;
  -webkit-box-shadow: 0 2px 4px 0 rgba(34,41,47,.5)!important;
  box-shadow: 0 2px 4px 0 rgba(34,41,47,.5)!important;
  background-size: 24px!important;
  display: flex;
  justify-content: center;
  align-items: center;
}
[dir] .swiper-button-prev, [dir] .swiper-button-next {
  height: 40px!important;
  width: 40px!important;
  color: #fff;
}
.swiper-button-prev{
  left: 0;
  top: 40%;
}
.swiper-button-next{
  right: 0;
  top: 40%;
}
</style>
